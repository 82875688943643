import React from "react";
import Header from "./layout/header";
import Footer from "./layout/footer";
import Banner from "./section/banner";
import OurServices from "./pages/OurServices";
import Appointment from "./pages/Appointment";
import Benefits from "./pages/Benefits";
import HairReplacement from "./pages/HairReplacement";
import Gallery from "./pages/Gallery";
import Reviews from "./pages/Reviews";
// import CustomerTestimonial from "./pages/CustomerTestimonial";
import Contact from "./pages/Contact";



const Home: React.FC = () => {

  return (
    <>
<Header />
<Banner/>
<OurServices/>
<Appointment/>
<Benefits />
<HairReplacement/>
<Gallery />
<Reviews />
{/* <CustomerTestimonial /> */}
<Contact />
<Footer />

    </>
  );
};

export default Home;
