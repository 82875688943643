import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import Header from "../../layout/header";
import { Link } from "react-router-dom";
import Footer from "../../layout/footer";
import ServisesSlider from "./ServisesSlider";
import "swiper/css/pagination";
import CustomerTestimonial from "../CustomerTestimonial";
import Appointment from "../Appointment";
import CountUp from 'react-countup';

const Customizedwigsformen = () => {
  return (
    <>
      <Header />
      <section className="about-banner">
        <div className="container">
          <div className="banner-content">
            <h2 className="wow fadeInLeft" data-wow-duration="1s">Customized Wigs For Men</h2>
          </div>
          <div className="sc_layouts_title_breadcrumbs wow fadeInLeft">
            <div className="breadcrumbs">
            <a className="breadcrumbs_item home" href="/radiance/">Home</a>
            <span className="breadcrumbs_delimiter"></span>
            <span className="breadcrumbs_item current">All Services</span>
            <span className="breadcrumbs_delimiter"></span>
            <span className="breadcrumbs_item current">Customized Wigs For Men</span>
          </div>
        </div>
        </div>
      </section>

      <section className="contact-section pb-0">
        <div className="container">
          <div className="section-header text-center mb-4">
            <h2 className="text-title text-uppercase wow fadeInUp">
              High-Quality Natural Hair Wigs for Men
            </h2>
            <p className="text-hr  mt-0"></p>
          </div>
        </div>
      </section>

      <section id="services" className="services pt-4">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="img-holder">
                <Link to="/">
                  <img
                    src="images/benefits/customized-wigs-for-men001.png"
                    alt="logo"
                    className="img-fluid wow animate__animated animate__slideInLeft"
                  />
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="d-flex align-items-center h-100">
                <div className="text-left">
                  <div className="process-text">
                    <p className="mb-2 text-subtitle-services wow animate__animated animate__slideInRight" data-wow-duration="2s">
                      Every man loves to have an optimum quantity of hairs
                      overhead to showcase an attractive sense of personality.
                      It will be disheartening for anyone to feel the loss of
                      hair on the head due to ageing or any other
                      disease-related issue. Nothing appears more painful than
                      finding yourself partially or completely bald and afraid
                      to face the people outside.
                    </p>
                    <p className="mb-2 text-subtitle-services  wow animate__animated animate__slideInRight" data-wow-duration="2s">
                      If you are experiencing such hard times of having less or
                      no hair over the head, then Radiance Hair Studio has the
                      right solution for you. We offer customized hair wigs for
                      men of different age groups, stature, and preference. The
                      top-quality hair wigs we offer save you from the
                      embarassment of looking bald in front of near and dear
                      ones, office colleagues, or strangers around.
                    </p>
                    <p className="mb-2 text-subtitle-services  wow animate__animated animate__slideInRight" data-wow-duration="2s">
                      Our extensive range of customized wigs in Delhi for men is
                      not inclined to one type only. Here, we offer different
                      varieties of hair wigs for men that can be customized
                      accordingly as per the personal needs of customized.
                      Moreover, we offer an invasive and non-surgical way of
                      providing customized wigs of any size, strength, style,
                      and volume as per your matching preference.
                    </p>
                  </div>
                  <button className="btn btn-theme mt-4">Learn More</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-lg counter-section-three">
        <div className="container">
          <div className="row g-3">
            <div className="col-md-3 col-6 pbminfotech-ele-fid-style-2 wow fadeInUp">
              <div className="pbmit-fld-contents">
                <h4 className="pbmit-fid-inner ">
                  <CountUp start={0} end={1} duration={2} delay={0}/>
                  <span className="pbmit-number-rotate numinate">M</span>
                  <span className="pbmit-fid-sub">
                    <sub>+</sub>
                  </span>
                </h4>
                <h3 className="pbmit-fid-title">
                  <span>
                    Satisfied Patients
                    <br />
                  </span>
                </h3>
              </div>
            </div>
            <div className="col-md-3 col-6 pbminfotech-ele-fid-style-2 wow fadeInUp">
              <div className="pbmit-fld-contents">
                <h4 className="pbmit-fid-inner">
                <CountUp start={0} end={10} duration={3} delay={0}/>
                  <span className="pbmit-number-rotate numinate">K</span>
                  <span className="pbmit-fid-sub">
                    <sub>+</sub>
                  </span>
                </h4>
                <h3 className="pbmit-fid-title">
                  <span>
                    Hair Transplant
                    <br />
                  </span>
                </h3>
              </div>
            </div>
            <div className="col-md-3 col-6 pbminfotech-ele-fid-style-2 wow fadeInUp">
              <div className="pbmit-fld-contents">
                <h4 className="pbmit-fid-inner">
                <CountUp start={0} end={12} duration={5} delay={0}/>
                 
                  <span className="pbmit-fid-sub">
                    <sub>+</sub>
                  </span>
                </h4>
                <h3 className="pbmit-fid-title">
                  <span>
                    National Awards
                    <br />
                  </span>
                </h3>
              </div>
            </div>
            <div className="col-md-3 col-6 pbminfotech-ele-fid-style-2 wow fadeInUp">
              <div className="pbmit-fld-contents">
                <h4 className="pbmit-fid-inner">
                <CountUp start={0} end={13} duration={5} delay={0}/>
                  <span className="pbmit-fid-sub">
                    <sub>+</sub>
                  </span>
                </h4>
                <h3 className="pbmit-fid-title">
                  <span>
                    Years of Experience
                    <br />
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pbmit-bg-color-light section-lg ">
        <div className="container">
          <div className="row wow animate__animated animate__slideInUp">
            <div className="col-md-4">
              <div className="pbmit-ihbox-style-1">
                <div className="pbmit-ihbox-contents">
                  <div className="pbmit-ihbox-heading">
                    <h2 className="pbmit_custom_heading">
                      Why Hair Transplant is Required?
                    </h2>
                  </div>
                  <div className="pbmit-ihbox-content">
                    <div className="themesion-ul-list thsn-ul-type-icon">
                      <ul>
                        <li>
                          <i className="fa fa-check"></i> Hair thinning
                        </li>
                        <li>
                          <i className="fa fa-check"></i> Alopecia Areata
                        </li>
                        <li>
                          <i className="fa fa-check"></i> Minor scalp injury
                        </li>
                        <li>
                          <i className="fa fa-check"></i> Male pattern baldness
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="pbmit-ihbox-style-1">
                <div className="pbmit-ihbox-contents">
                  <div className="pbmit-ihbox-heading">
                    <h2 className="pbmit_custom_heading">
                      What are the benefits of hair transplant?
                    </h2>
                  </div>
                  <div className="pbmit-ihbox-content">
                    <div className="themesion-ul-list thsn-ul-type-icon">
                      <ul>
                        <li>
                          <i className="fa fa-check"></i> Improved aesthetic
                          appearance
                        </li>
                        <li>
                          <i className="fa fa-check"></i> Increased confidence
                        </li>
                        <li>
                          <i className="fa fa-check"></i> Restored hair in bald
                          spots
                        </li>
                        <li>
                          <i className="fa fa-check"></i> Covered receding
                          hairline
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="pbmit-ihbox-style-1">
                <div className="pbmit-ihbox-contents">
                  <div className="pbmit-ihbox-heading">
                    <h2 className="pbmit_custom_heading">
                      Why opt for Hair Transplant?
                    </h2>
                  </div>
                  <div className="pbmit-ihbox-content">
                    <div className="themesion-ul-list thsn-ul-type-icon">
                      <ul>
                        <li>
                          <i className="fa fa-check"></i> Allopathic Medication
                          won't work
                        </li>
                        <li>
                          <i className="fa fa-check"></i> Ayurveda, Homeopathy
                          treatment is ineffective
                        </li>
                        <li>
                          <i className="fa fa-check"></i> Hair Patch/Wig are
                          temporary
                        </li>
                        <li>
                          <i className="fa fa-check"></i> Hair transplant is the
                          best solution
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="counter-section-one" style={{ paddingTop: "200px" }}>
        <div className="container">
          <div
            className="counter-one-bg"
            style={{ padding: "45px 0 45px 40px" }}
          >
            <div className="row wow animate__animated animate__slideInRight">
              <div className="col-md-3">
                <div className="pbminfotech-ele-fid-style-1">
                  <div className="pbmit-fld-contents d-flex align-items-center">
                    <div className="pbmit-ihbox-icon">
                      <div className="pbmit-ihbox-icon-wrapper pbmit-ihbox-icon-type-image">
                        <img
                          width="100px"
                          src="https://www.reehairskin.com/hair-transplant/images/fda.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      className="pbmit-fld-wrap"
                      style={{ paddingLeft: "10px" }}
                    >
                      <h3 className="pbmit-fid-title text-center">
                        <span style={{ fontSize: "16px", fontWeight: "600" }}>
                          {" "}
                          USFDA <br />
                          Approved <br />
                          Procedures
                          <br />
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="pbminfotech-ele-fid-style-1">
                  <div className="pbmit-fld-contents d-flex align-items-center">
                    <div className="pbmit-ihbox-icon">
                      <div className="pbmit-ihbox-icon-wrapper pbmit-ihbox-icon-type-image">
                        <img
                          width="100px"
                          src="https://www.reehairskin.com/hair-transplant/images/tech.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      className="pbmit-fld-wrap"
                      style={{ paddingLeft: "10px" }}
                    >
                      <h3 className="pbmit-fid-title text-center">
                        <span style={{ fontSize: "16px", fontWeight: "600" }}>
                          {" "}
                          Most <br />
                          Advanced <br />
                          Technology
                          <br />
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="pbminfotech-ele-fid-style-1">
                  <div className="pbmit-fld-contents d-flex align-items-center">
                    <div className="pbmit-ihbox-icon">
                      <div className="pbmit-ihbox-icon-wrapper pbmit-ihbox-icon-type-image">
                        <img
                          width="100px"
                          src="https://www.reehairskin.com/hair-transplant/images/0emi.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      className="pbmit-fld-wrap"
                      style={{ paddingLeft: "10px" }}
                    >
                      <h3 className="pbmit-fid-title text-center">
                        <span style={{ fontSize: "16px", fontWeight: "600" }}>
                          {" "}
                          0% Cost
                          <br /> EMI
                          <br />
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="pbminfotech-ele-fid-style-1">
                  <div className="pbmit-fld-contents d-flex align-items-center">
                    <div className="pbmit-ihbox-icon">
                      <div className="pbmit-ihbox-icon-wrapper pbmit-ihbox-icon-type-image">
                        <img
                          width="100px"
                          src="https://www.reehairskin.com/hair-transplant/images/paper.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      className="pbmit-fld-wrap"
                      style={{ paddingLeft: "10px" }}
                    >
                      <h3 className="pbmit-fid-title text-center">
                        <span style={{ fontSize: "16px", fontWeight: "600" }}>
                          {" "}
                          Paperwork
                          <br /> Support
                          <br />
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <ServisesSlider/> */}

      <section id="services" className="services pt-0">
        <div className="container">
          <div className="row">
          
            <div className="col-md-6 col-12">
              <div className="d-flex align-items-center h-100">
                <div className="text-left">
                  <h2 className="title text-title-services text-uppercase border-left text-left wow animate__animated animate__slideInLeft">
                    100% Natural Human Hair Wigs
                  </h2>
                  <div className="process-text">
                    <p className="mb-2 about-text wow animate__animated animate__slideInLeft">
                      Feel young and appear impressive once again by getting
                      your confidence and life back in terms of having stylish
                      hair on your head. Our supreme quality of men’s hair wigs
                      is made up of 100% human hair with no other thing used to
                      create the same. By getting in touch with our expert
                      hairstylists, you will get an end-to-end non-surgical
                      treatment of fixing a particular hair wig on your head.
                    </p>
                    <p className="mb-2 about-text wow animate__animated animate__slideInLeft">
                      SThe touch and feel of wigs for men will be completely
                      realistic and hard for anyone to see the artificial making
                      of hair. Thus, by opting for this hair growing service
                      i.e. customized wigs, you get the desired outlook to face
                      the people with utmost confidence.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 pos-rel wow animate__animated animate__slideInRight">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                }}
              >
                <ReactCompareSlider
                  boundsPadding={0}
                  changePositionOnHover
                  itemOne={
                    <ReactCompareSliderImage
                      alt="Image one"
                      src="https://lapiel.in/wp-content/uploads/2023/03/LaPiel_Hair_Transplantation_Before.png"
                    />
                  }
                  itemTwo={
                    <ReactCompareSliderImage
                      alt="Image two"
                      src="https://lapiel.in/wp-content/uploads/2023/03/LaPiel_Hair_Transplantation_After.png"
                    />
                  }
                  position={50}
                  style={{
                    flexGrow: 1,
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <CustomerTestimonial />

      <section className="section-lg counter-section-three">
        <div className="container">
          <div className="row g-3">
            <div className="col-md-3 col-6 pbminfotech-ele-fid-style-2 wow fadeInUp">
              <div className="pbmit-fld-contents">
                <h4 className="pbmit-fid-inner">
                  <CountUp start={0} end={1} duration={2} delay={0}/>
                  <span className="pbmit-number-rotate numinate">M</span>
                  <span className="pbmit-fid-sub">
                    <sub>+</sub>
                  </span>
                </h4>
                <h3 className="pbmit-fid-title">
                  <span>
                    Satisfied Patients
                    <br />
                  </span>
                </h3>
              </div>
            </div>
            <div className="col-md-3 col-6 pbminfotech-ele-fid-style-2 wow fadeInUp">
              <div className="pbmit-fld-contents">
                <h4 className="pbmit-fid-inner">
                <CountUp start={0} end={10} duration={3} delay={0}/>
                  <span className="pbmit-number-rotate numinate">K</span>
                  <span className="pbmit-fid-sub">
                    <sub>+</sub>
                  </span>
                </h4>
                <h3 className="pbmit-fid-title">
                  <span>
                    Hair Transplant
                    <br />
                  </span>
                </h3>
              </div>
            </div>
            <div className="col-md-3 col-6 pbminfotech-ele-fid-style-2 wow fadeInUp">
              <div className="pbmit-fld-contents">
                <h4 className="pbmit-fid-inner">
                <CountUp start={0} end={12} duration={5} delay={0}/>
                 
                  <span className="pbmit-fid-sub">
                    <sub>+</sub>
                  </span>
                </h4>
                <h3 className="pbmit-fid-title">
                  <span>
                    National Awards
                    <br />
                  </span>
                </h3>
              </div>
            </div>
            <div className="col-md-3 col-6 pbminfotech-ele-fid-style-2 wow fadeInUp">
              <div className="pbmit-fld-contents">
                <h4 className="pbmit-fid-inner">
                <CountUp start={0} end={13} duration={5} delay={0}/>
                  <span className="pbmit-fid-sub">
                    <sub>+</sub>
                  </span>
                </h4>
                <h3 className="pbmit-fid-title">
                  <span>
                    Years of Experience
                    <br />
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Appointment />

      <div id="faq" className="faq section-bg pt-5 pb-5 css-144ctnr">
        <div className="container">
          <div className="section-title ">
            <div className="w-full px-4 mx-auto mt-7 xl:px-0 max-w-7xl ">
              <h2 className="flex justify-center text-3xl text-center text-black fw-bold xl:text-6xl wow animate__animated animate__slideInUp">
                Frequently asked Questions
              </h2>
            </div>
          </div>

          <div className="faq-list ">
            <ul>
              <li className="wow animate__animated animate__slideInRight">
                <i>
                  <img src="favicon.ico" alt="" className="icon-help" />
                </i>
                <a
                  data-bs-toggle="collapse"
                  className="collapse"
                  data-bs-target="#faq-list-1"
                >
                 How should I choose the doctor for hair transplant surgery?
                  <i className="fa fa-chevron-down icon-show"></i>
                  <i className="fa fa-chevron-up icon-close"></i>
                </a>
                <div
                  id="faq-list-1"
                  className="collapse show"
                  data-bs-parent=".faq-list"
                >
                  <p>
                  The surgeon should have prior experience in hair transplant surgery. You can ask the
								surgeon about their history of successful hair transplants surgeries to narrow down your
								options to the most suitable one.
                  </p>
                </div>
              </li>

              <li data-aos-delay="100" className="wow animate__animated animate__slideInLeft">
                <i>
                  <img src="favicon.ico" alt="" className="icon-help" />
                </i>
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#faq-list-2"
                  className="collapsed"
                >
                 What is the minimum age to undergo a hair transplant?
                  <i className="fa fa-chevron-down icon-show"></i>
                  <i className="fa fa-chevron-up icon-close"></i>
                </a>
                <div
                  id="faq-list-2"
                  className="collapse"
                  data-bs-parent=".faq-list"
                >
                  <p>
                  The minimum age to undergo a hair transplant is 18 years. However, you can undergo the
								procedure as you feel fit. It is usually done to restore hair due to hair loss, a common
								problem in middle-aged men and women.
                  </p>
                </div>
              </li>

              <li data-aos-delay="200" className="wow animate__animated animate__slideInRight">
                <i>
                  <img src="favicon.ico" alt="" className="icon-help" />
                </i>
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#faq-list-3"
                  className="collapsed"
                >
                  How safe is a hair transplant?
                  <i className="fa fa-chevron-down icon-show"></i>
                  <i className="fa fa-chevron-up icon-close"></i>
                </a>
                <div
                  id="faq-list-3"
                  className="collapse"
                  data-bs-parent=".faq-list"
                >
                  <p>
                  Hair transplant is a safe and effective procedure if done by an expert cosmetic surgeon.
								The hair follicles grow back naturally to give your hair a more natural appearance. In
								addition, an experienced surgeon ensures minimal post-operative risks and complications.
                  </p>
                </div>
              </li>

              <li data-aos-delay="300" className="wow animate__animated animate__slideInLeft">
                <i>
                  <img src="favicon.ico" alt="" className="icon-help" />
                </i>{" "}
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#faq-list-4"
                  className="collapsed"
                >
                 Will I get a natural look after a hair transplant?
                  <i className="fa fa-chevron-down icon-show"></i>
                  <i className="fa fa-chevron-up icon-close"></i>
                </a>
                <div
                  id="faq-list-4"
                  className="collapse"
                  data-bs-parent=".faq-list"
                >
                  <p>
                  Yes, there are high chances your new hair will appear natural. However, the
								effectiveness of surgery depends on the surgical expertise of the surgeon and how
								strictly the patient follows post-operative care. The hair follicles shed within a few
								weeks after the surgery, and new hair begins to grow from the transplanted roots.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <section id="contact-section" className="contact-section pt-5  pb-0">
        <div className="container">
          <header className="section-header text-center mb-4">
            <h2 className="text-title text-uppercase">
              Key Benefits of Wearing Stylish Hair Wig for Men
            </h2>
            <p className="text-hr  mt-0"></p>
          </header>
          <br></br>
          <div className="process-text">
            <p className="mb-2 customizedwigs">
              Hair wig for men is no longer considered as a temporary men’s
              accessory. But a permanent solution to cover up your head and
              allow you appear much younger than the actual age. We at Radiance
              Hair Studio, offer human tied hair wigs for men that not only
              provide an enhanced style statement but additional benefits as
              well.
            </p>
          </div>
          <div className="feat bg-gray pt-5 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-sm-6">
                  <div className="item">
                    <h6>Comfortable</h6>
                    <p>
                      Hair wigs for men are specially manufactured using natural
                      human hairs. The experts make the best use of advanced
                      technology to create such light in weight wigs that are
                      comfortable to wear all day long.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <div className="item">
                    <h6>Convenient</h6>
                    <p>
                      The wigs offer the highest level of wearing convenience.
                      It is easy to wear and pull off, without requiring any
                      professional assistance.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <div className="item">
                    <h6>Less Maintenance</h6>
                    <p>
                      The diverse variety of wigs for men we offer demand less
                      maintenance. There is no need to regularly brush up,
                      shampoo, blow-drying, or anything else.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="item">
                    <h6>Variety</h6>
                    <p>
                      Hair wigs for men come in a variety of styles, materials,
                      and hair lengths. Select the best matching your face cut,
                      complexion, and other appearance traits accordingly.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="item">
                    <h6>Shades</h6>
                    <p>
                      Choose the best hair wig for men in Delhi in whatever
                      shade you like. Find a diverse range of light, bright, and
                      medium shades in hair wigs for men to appear attractive to
                      the eyes of onlookers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <Footer />
    </>
  );
};

export default Customizedwigsformen;
