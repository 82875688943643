import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";

const Gallery = () => {
  return (
    <section id="gallery" className="gallery-wrap">
     <div className="overlay"></div>
      <div className="row">
					<div className="col-md-12 col-12">
      <div className="">
          <header className="section-header text-center mb-4">
            <h2 className="theme-text-title text-uppercase">Gallery</h2>
            <p className="text-subtitle pb-0 text-white">
            Unleash your hair's radiance with Radiance Hair Studio
            </p>
            <p className="text-hr mt-0"></p>
          </header>
          <br></br>
          <div className="col-md-12 col-12 section">
						<div className="">        
                <Swiper
                className="slider "
                  spaceBetween={20}
                  slidesPerView={"auto"}
                  centeredSlides={true}
               
                  
                  loop={true}
                  autoplay={true}
           
                  
                  modules={[Autoplay, Navigation]}
                  
                  breakpoints={{
                    0: {
                      width: 0,
                      slidesPerView: 1,
                    },
                    680: {
                      width: 680,
                      slidesPerView: 3,
                    },
                    1200: {
                     
                      slidesPerView: 4,
                    },
                  }}
                >
                  {/* {flagSlider.map((val, i) => ( */}
                    <SwiperSlide>
                      <div className="gtco-testimonials1 custom-slide">
                        <div className="card">
                            <img  src='images/gallery/1.png' alt='test' />
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="gtco-testimonials1 custom-slide">
                        <div className="card">
                            <img  src='images/gallery/2.png' alt='test' />
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="gtco-testimonials1 custom-slide">
                        <div className="card">
                            <img  src='images/gallery/3.png' alt='test' />
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="gtco-testimonials1 custom-slide">
                        <div className="card">
                            <img  src='images/gallery/4.png' alt='test' />
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="gtco-testimonials1 custom-slide">
                        <div className="card">
                            <img  src='images/gallery/5.png' alt='test' />
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="gtco-testimonials1 custom-slide">
                        <div className="card">
                            <img  src='images/gallery/6.png' alt='test' />
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="gtco-testimonials1 custom-slide">
                        <div className="card">
                            <img  src='images/gallery/7.png' alt='test' />
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="gtco-testimonials1 custom-slide">
                        <div className="card">
                            <img  src='images/gallery/8.png' alt='test' />
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="gtco-testimonials1 custom-slide">
                        <div className="card">
                            <img  src='images/gallery/9.png' alt='test' />
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="gtco-testimonials1 custom-slide">
                        <div className="card">
                            <img  src='images/gallery/10.png' alt='test' />
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="gtco-testimonials1 custom-slide">
                        <div className="card">
                            <img  src='images/gallery/12.png' alt='test' />
                        </div>
                      </div>
                    </SwiperSlide>
                  {/* ))} */}
                </Swiper>
              </div>
            </div>
          </div>
        </div>

        </div>
       
    </section>

  );
  
};


export default Gallery;
