import React from "react";
import { Link } from "react-router-dom";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import Appointment from "../Appointment";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import ServisesSlider from "./ServisesSlider";
import "swiper/css/pagination";



const Customizedwigsforwomen = () => {
  return (
    <>
      <Header />
      <section className="about-banner">
        <div className="container">
          <div className="banner-content">
            <h2>Customized Wigs For Women</h2>
          </div>
        </div>
      </section>

      <section className="contact-section pb-0">
        <div className="container">
          <div className="section-header text-center mb-4">
          <h2 className="text-title text-uppercase ">
          High-Quality Natural Hair Wigs for Women
            </h2>
            <p className="text-hr  mt-0"></p>
          </div>
          </div>
      </section>
      <section id="services" className="services pt-4">
				<div className="container">
					<div className="row">
						<div className="col-md-6 col-12">
							<div className="img-holder">
							<Link to="/"><img src="images/benefits/Women.png" alt="logo" className="img-fluid" /></Link>
							</div>
						</div>
						<div className="col-md-6 col-12">
							<div className="d-flex align-items-center h-100">
								<div className="text-left">
									<div className="process-text">
							<p className="mb-2 text-subtitle-services">
              Facing a hair loss issue is really disheartening. As a female hair
              is your greatest asset to showcase a personal sense of style
              physically and utmost confidence mentally. However, sudden or long
              term issues of partial or total hair loss on the head lead to
              declining self-confidence and beauty.</p>
							<p className="mb-2 text-subtitle-services">
              No longer worry about that, as we are here to brighten up your
              life with the best hair solutions for women in Delhi. Our
              specialized service of customized wigs for Women is specially
              offered for females to complete their outlook despite having
              practical or complete hair loss on heads. No matter, if you lose
              hair strands due to ageing, going under the treatment of
              chemotherapy, or suffered from a medical condition like alopecia,
              we have the finest and non-surgical hair solution for you.</p>
						
									</div>
									<button className="btn btn-theme mt-4">Learn More</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
      <ServisesSlider/> 

      <section id="services" className="services pt-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="d-flex align-items-center h-100">
                <div className="text-left">
                  <h2 className="title text-title-services text-uppercase border-left text-left">
                    Customized Wigs for You
                  </h2>
                  <div className="process-text">
                    <p className="mb-2 about-text">
                      There is no point in feeling remorse about your
                      compromised outlook due to constant hair loss. We have
                      your back at this time to provide you customized wigs
                      solution that appears completely natural and realistic.
                      Rely on the expertise of our hairstylists that have helped
                      hundreds of female customers fix the suitable hair wig
                      that helps to enhance their outlook.
                    </p>
                    <p className="mb-2 about-text">
                      Here at Radiance Hair Studio, we believe in maintaining an
                      optimum level of quality, in terms of offering 100% human
                      hair. Simply choose your own style, length, best fit, and
                      shade of the customized wig full of natural hair to
                      complete your personality.
                    </p>
                    <p className="mb-2 about-text">
                      In addition, the best wigs for women in Delhi NCR we offer
                      provide you comfort in wearing and taking out easily while
                      at home. It can swiftly stay in your drawer or bag to
                      change your outlook, especially in front of others.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 pos-rel">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                }}
              >
                <ReactCompareSlider
                  boundsPadding={0}
                  changePositionOnHover
                  itemOne={
                    <ReactCompareSliderImage
                      alt="Image one"
                      src="https://lapiel.in/wp-content/uploads/2023/03/LaPiel_Hair_Transplantation_Before.png"
                    />
                  }
                  itemTwo={
                    <ReactCompareSliderImage
                      alt="Image two"
                      src="https://lapiel.in/wp-content/uploads/2023/03/LaPiel_Hair_Transplantation_After.png"
                    />
                  }
                  position={50}
                  style={{
                    flexGrow: 1,
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Appointment/>
      <section id="services" className="services ">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="img-holder">
                <Link to="/">
                  <img
                    src="images/customized-wigs-for-women.png"
                    alt="Our Mission"
                    className="img-fluid"
                  />
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="d-flex align-items-center h-100">
                <div className="text-left">
                  <h2 className="title text-title-services text-uppercase border-left text-left">
                    Get Free Consultation Today!
                  </h2>
                  <div className="process-text">
                    <p className="mb-2 about-text">
                      It’s time to set aside your worries and set up for a new
                      beginning in life. Make the most out of this non-surgical
                      customized wigs service for women with us to enhance your
                      appearance to a maximum extent.
                    </p>
                    <p className="mb-2 about-text">
                      Get in touch with our real-time hairstylists and experts
                      to know more about customized, how to choose the best one,
                      and the process to put it on gently.{" "}
                    </p>
                  </div>
                  <button className="btn btn-theme mt-4">Get in touch</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
   
      <Footer />
    </>
  );
};

export default Customizedwigsforwomen;
