import React from "react";
import { Link } from "react-router-dom";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import ServisesSlider from "./ServisesSlider";
import Appointment from "../Appointment";

import "swiper/css/pagination";

const Clipinonhairextensionsforwomen = () => {
  return (
    <>
      <Header />
      <section className="about-banner">
        <div className="container">
          <div className="banner-content">
            <h2>Tape In & Clip In on Hair Extensions for Women</h2>
          </div>
        </div>
      </section>

      <section className="contact-section pb-0">
        <div className="container">
          <div className="section-header text-center mb-4">
          <h2 className="text-title text-uppercase ">
          Hair Extensions for Women
            </h2>
            <p className="text-hr  mt-0"></p>
          </div>
          </div>
      </section>


      <section id="services" className="services pt-4">
				<div className="container">
					<div className="row">
						<div className="col-md-6 col-12">
							<div className="img-holder">
							<Link to="/"><img src="images/benefits/hair-extensions-for-women.png" alt="logo" className="img-fluid" /></Link>
							</div>
						</div>
						<div className="col-md-6 col-12">
							<div className="d-flex align-items-center h-100">
								<div className="text-left">
									<div className="process-text">
							<p className="mb-2 text-subtitle-services">
              Looking to increase the volume of your existing head hair and
              makes it look fuller and longer? Undoubtedly, hair extensions for
              women are the best way to achieve the desired outlook without
              undergoing any painful treatment or surgery. With hair extensions,
              females have the opportunity to experiment with their outlook for
              any particular occasion or routine life. We at Radiance Hair
              Studio, assist all the women to lay hands on practically possible
              options of adding extra layers of hair to the existing one and
              become a whole new person altogether.</p>
							<p className="mb-2 text-subtitle-services">
              Here, we offer you different types of hair extensions of
              distinctive length, style, shade, and volume that particularly
              match your style and personality. Most importantly, we offer you
              pain-free and non-sewn options for fixing the desired hair
              extension over the head without needing any professional
              assistance. Look forward to choosing between Tape In or Clip In on
              hair extensions for women to fix the extra layer of hair perfectly
              and seamlessly.</p>
							<p className="mb-2 text-subtitle-services">
              Depending on the requirements, our expert team of hairstylists
              offers both ways of fixing one or several layers of extensions on
              the head and that too for a matte of few days or a week.</p>
									</div>
									<button className="btn btn-theme mt-4">Learn More</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

    <ServisesSlider/>

      <section id="services" className="services pt-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="d-flex align-items-center h-100">
                <div className="text-left">
                  <h2 className="title text-title-services text-uppercase border-left text-left">
                    Tape In on Hair Extensions for Women
                  </h2>
                  <div className="process-text">
                    <p className="mb-2 about-text">
                      Tape In fixing and removal of hair extensions for women in
                      Delhi is the simplest way to create your desired
                      personality, whenever you want. As the name itself
                      implies, we offer Tape In as adherence to adding a medical
                      grade tape inside the hair extension that is quickly fixed
                      on your head. There is no way such extension fixing
                      techniques comes out of the head as it sticks seamlessly
                      on the smooth or even hair surface as well. The tape-in
                      extensions keep your outlook natural and realistic without
                      telling anyone that is a hair extension applied on the
                      head. Moreover, it is simple to fix and take out the
                      extension whenever you want and provides an average
                      fixation time of several hours in a day.
                    </p>
                  </div>
                  {/* <button className="btn btn-theme mt-4">Learn More</button> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="img-holder">
                <Link to="/">
                  <img
                    src="images/hair-extensions-for-women-1.png"
                    alt="Our Mission"
                    className="img-fluid"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="services" className="services">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="img-holder">
                <Link to="/">
                  <img
                    src="images/human-hair-extension(2).png"
                    alt="Our Mission"
                    className="img-fluid"
                  />
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="d-flex align-items-center h-100">
                <div className="text-left">
                  <h2 className="title text-title-services text-uppercase border-left text-left">
                    Clip In on Hair Extensions for Women
                  </h2>
                  <div className="process-text">
                    <p className="mb-2 about-text">
                      Clip-in extensions are the easiest and fastest way to
                      achieve a longer, fuller, and voluminous outlook. Females
                      do not need a professional approach to fix and undone
                      clip-in extension from the head. Several invisible clips
                      are attached to the extension allowing females to easily
                      clip in the same and get ready within a few minutes. It is
                      the fastest and affordable way to apply for several hair
                      extensions without damaging or breaking your natural hair.
                    </p>
                    <p className="mb-2 about-text">
                      Get to know more about Tape In & Clip In on hair
                      extensions for women by receiving a free consultation from
                      our experts.
                    </p>
                  </div>
                  {/* <button className="btn btn-theme mt-4">Learn More</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
   <Appointment/>
      <Footer />
    </>
  );
};

export default Clipinonhairextensionsforwomen;
