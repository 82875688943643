import { Link } from "react-router-dom";
const Vision = () => {
  return (
    <>
      <section id="services" className="services pt-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="img-holder ">
                <Link to="/">
                  <img
                    src="images/about/about1.png"
                    alt="Our Vision"
                    className="img-fluid wow animate__animated animate__backInLeft"
                  />
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="d-flex align-items-center h-100">
                <div className="text-left">
                  <h2 className="title text-title-services text-uppercase border-left text-left wow animate__animated animate__zoomIn">
                    Our Vision
                  </h2>
                  <div className="process-text">
                    <p className="mb-2 about-text wow animate__animated animate__slideInRight">
                      The vision of Radiance Hair Studio is to build a community
                      of people who are happy, confident, and appear attractive
                      in outlook. To achieve the same, we provide our immense
                      support to men and women facing short term or long term
                      hair fall or loss issues. Thus, our goal is to provide
                      industry-best hair growing solutions to individuals facing
                      the hardships of less or no hair on the head. Our team of
                      hair experts makes sure the fact that clients explained
                      all the hair-related issues seamlessly and avail the best
                      possible solution to enhance their outlook to an optimum
                      extent.
                    </p>
                  </div>
                  {/* <button className="btn btn-theme mt-4">Learn More</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Vision;
