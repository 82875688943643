const Contact = () => {
    return (
        <>

<section id="contact-section" className="contact-section pt-0 pb-5">
				<div className="container">
					<header className="section-header text-center mb-4">
						<h2 className="text-title text-uppercase wow animate__animated animate__slideInUp">Get in touch with us</h2>
						<p className="text-subtitle pb-0 wow animate__animated animate__slideInUp">Experience A New Level Of Radiance With Our Hair Solutions </p>
						<p className="text-hr mt-0"></p>
					</header><br></br>
					<div className="row">
						<div className="col-xl-6 col-lg-6 col-md-12 wow animate__animated animate__slideInLeft">
						<div className="map-wrap">
								<div className="mapouter">
									<div className="gmap_canvas ">
										<iframe width="100%" height="70%" id="gmap_canvas" src="https://maps.google.com/maps?q=radiance hair and skin clinic B-311 CR park main road block B chittranjan park delhi&t=&z=10&ie=UTF8&iwloc=&output=embed"></iframe>
									</div>
								</div>
							</div>
							<div className="border-wrap">
								<div className="address-wrap">
									<div className="row">
										<div className="col-xl-12 col-lg-12 col-md-12">
											<div className="address-col">
												<h4 className="wow wow fadeInUp">OUR ADDRESS</h4>
												<p className="mb-0 wow fadeInUp">A-66, 3rd Floor, Beside Metro Station Gate No 5, Block A, Rajouri Garden, New Delhi, Delhi 110027</p>
											
											</div>
										</div>
										<div className="col-xl-12 col-lg-12 col-md-12">
											<div className="phone-col">
												<h4 className="wow fadeInUp">CALL US</h4>
												<p className="mb-0 wow fadeInUp">+91-99585-85649</p>
										
											</div>
										</div>
										<div className="col-xl-12 col-lg-12 col-md-12">
											<div className="eamil-col">
												<h4 className="wow fadeInUp">EMAIL US</h4>
												<p className="mb-0 wow fadeInUp">contact@radiancehairstudio.com</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 animate__animated animate__slideInRight">
						<div className="map-wrap">
								<div className="mapouter">
									<div className="gmap_canvas">
										<iframe width="100%" height="70%" id="gmap_canvas" src="https://maps.google.com/maps?q=radiance hair and skin clinic B-311 CR park main road block B chittranjan park delhi&t=&z=10&ie=UTF8&iwloc=&output=embed"></iframe>
									</div>
								</div>
							</div>
							<div className="border-wrap">
								<div className="address-wrap">
									<div className="row">
										<div className="col-xl-12 col-lg-12 col-md-12">
											<div className="address-col">
												<h4 className="wow fadeInUp">OUR ADDRESS</h4>
												<p className="mb-0 wow fadeInUp">B-311, Basement, CR Park Main Road, Chittaranjan Park, </p>
												<p className="mb-0 wow fadeInUp">New Delhi, Delhi 110019</p>
											</div>
										</div>
										<div className="col-xl-12 col-lg-12 col-md-12">
											<div className="phone-col">
												<h4 className="wow fadeInUp">CALL US</h4>
												<p className="mb-0 wow fadeInUp">+ 084487 21457</p>
											</div>
										</div>
										<div className="col-xl-12 col-lg-12 col-md-12">
											<div className="eamil-col">
												<h4 className="wow fadeInUp">EMAIL US</h4>
												<p className="mb-0 wow fadeInUp">contact@radiancehairstudio.com</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row mt-4">
				

					</div>
				</div>
			</section>
            </>   
    );
}
 
export default Contact;


