import React from "react";
import { Link } from "react-router-dom";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import ServisesSlider from "./ServisesSlider";
import "swiper/css/pagination";
import Appointment from "../Appointment";


const Nonsurgicalhairreplacementforwomen = () => {
  return (
    <>
      <Header />
      <section className="about-banner">
        <div className="container">
          <div className="banner-content">
            <h2>Non-Surgical Hair Replacement for Women</h2>
          </div>
        </div>
      </section>

      <section className="contact-section pb-0">
        <div className="container">
          <div className="section-header text-center mb-4">
          <h2 className="text-title text-uppercase ">
          Non-Surgical Hair Replacement for Women
            </h2>
            <p className="text-hr  mt-0"></p>
          </div>
          </div>
      </section>

      <section id="services" className="services pt-4">
				<div className="container">
					<div className="row">
						<div className="col-md-6 col-12">
							<div className="img-holder">
							<Link to="/"><img src="images/benefits/non-surgical-hair-replacement-for-women.png" alt="logo" className="img-fluid" /></Link>
							</div>
						</div>
						<div className="col-md-6 col-12">
							<div className="d-flex align-items-center h-100">
								<div className="text-left">
									<div className="process-text">
							<p className="mb-2 text-subtitle-services">
              Are you losing all your hopes of getting that attractive outlook
              back due to suffering from constant hair loss issues? If yes, then
              we at Radiance Hair Studio will take all your worries and once
              again allow you to appear attractive and beautiful. For all the
              females, we are here to offer our advanced technology equipped
              non-surgical hair replacement for women in Delhi/NCR. Get your
              head full of natural flowing human hair to appear as the most
              appealing personality in front of others. Simply rely on our
              expert team of hair specialists to avail the painless,
              cost-effective, and fast way to receive hair growing treatment for
              a few hours only.
              </p>
									</div>
									<button className="btn btn-theme mt-4">Learn More</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

     <ServisesSlider/>

      <section id="services" className="services pt-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="d-flex align-items-center h-100">
                <div className="text-left">
                  <h2 className="title text-title-services text-uppercase text-left">
                    What is Non-Surgical Hair Replacement for Women?
                  </h2>
                  <div className="process-text">
                    <p className="mb-2 about-text">
                      If you are wondering, what exactly is this non-surgical
                      hair replacement, how does it work, and why it is
                      beneficial for you, then get all your answers here. For
                      those who feel uncomfortable with the surgical way of
                      growing natural hair on the head or for those having not
                      that much budget to receive such treatment. For them, we
                      offer an end-to-end non-surgical way of restoring your
                      bouncy glory back to terms by growing hair in a painless
                      manner.
                    </p>
                    <p className="mb-2 about-text">
                      Our expert team of hairstylists will use 100% human hair
                      in the form of a hair patch for women or the complete hair
                      wig to apply on your scalp. Our doing a thorough
                      investigation of your head, examining the current density,
                      and empty portions on the head, we offer the best level of
                      non- surgical hair replacement treatment to women of all
                      age groups.
                    </p>
                    <p className="mb-2 about-text">
                      We are here to offer you custom-made hair replacement
                      solutions in the form of a hair patch with having a thin
                      membrane to attach perfectly to your scalp. Our experts
                      make sure the fact that the customized hair patch fits
                      your head perfectly and looks natural to the eyes of
                      onlookers around. The best part is the human hair covering
                      your bald spots and thinning zones very well to provide
                      your expected level of hair thickness, desired volume,
                      colour, and favourable texture.
                    </p>
                  </div>
                  {/* <button className="btn btn-theme mt-4">Learn More</button> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="img-holder image-top  ">
                <Link to="/">
                  <img
                    src="images/non-surgical-hair-replacement-for-women.png"
                    alt="Our Mission"
                    className="img-fluid"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

<Appointment/>
      <Footer />
    </>
  );
};

export default Nonsurgicalhairreplacementforwomen;
