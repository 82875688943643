import { Link } from "react-router-dom";
const OurServices = () => {
    return (
        <>
<section id="services" className="services">
				<div className="container">
					<div className="row">
						<div className="col-md-6 col-12">
							<div className="img-holder">
							<Link to="/"><img src="images/services.png" alt="logo" className="img-fluid wow animate__animated animate__slideInLeft" /></Link>
							</div>
						</div>
						<div className="col-md-6 col-12">
							<div className="d-flex align-items-center h-100">
								<div className="text-left">
									<h2 className="title text-title-services text-uppercase border-left text-left wow animate__animated animate__zoomIn" data-wow-delay="0.2s">Our Services</h2>
									<div className="process-text">
										<p className="mb-2 text-subtitle-services wow animate__animated animate__slideInRight" data-wow-delay="3s">
										Radiance Hair Studio offers a wide range of hair solutions, including wigs, patches, bonding, weaving, and clipping. Our expert stylists are dedicated to providing personalized solutions catering to your unique needs, ensuring you feel confident and beautiful daily. Whether you’re looking to enhance your natural hair or cover bald spots, or a complete makeover, Radiance Hair Studio has you covered. 
										</p>
										<p className="mb-2 text-subtitle-services wow animate__animated animate__slideInRight" data-wow-delay="0.3s">
										We provide everything from hair replacement, hair extensions, customized wigs, hair bonding, and hair weaving to tape-in and clip-in extensions for both men and women. Our products are customized according to your scalp measurement done by our hair experts. A sample of your hair is also taken to match the texture of the hair wig or patch. 
										</p>
										<p className="mb-2 text-subtitle-services wow animate__animated animate__slideInRight" data-wow-delay="0.3s">
										With a wide range of options to choose from, we can help you find the perfect hair replacement solution that fits your needs and style.</p>
									</div>
									<button className="btn btn-theme mt-4 wow animate__animated animate__slideInRight" data-wow-delay="0.3s">Learn More</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
        
            </>   
    );
}
 
export default OurServices;