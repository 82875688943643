import React from "react";
import { Link } from "react-router-dom";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import ServisesSlider from "./ServisesSlider";
import Appointment from "../Appointment";
import "swiper/css/pagination";

const Hairfixingformen = () => {
  return (
    <>
      <Header />
      <section className="about-banner">
        <div className="container">
          <div className="banner-content">
            <h2>Hair Fixing for Men</h2>
          </div>
        </div>
      </section>

      <section className="contact-section pb-0">
        <div className="container">
          <div className="section-header text-center mb-4">
          <h2 className="text-title text-uppercase ">
          Hair Fixing for Men
            </h2>
            <p className="text-hr  mt-0"></p>
          </div>
          </div>
      </section>

      <section id="services" className="services pt-4">
				<div className="container">
					<div className="row">
						<div className="col-md-6 col-12">
							<div className="img-holder">
							<Link to="/"><img src="images/benefits/hair-fixing-for-men (2).png" alt="logo" className="img-fluid" /></Link>
							</div>
						</div>
						<div className="col-md-6 col-12">
							<div className="d-flex align-items-center h-100">
								<div className="text-left">
									<div className="process-text">
							<p className="mb-2 text-subtitle-services">
              Losing hair at a young age is a matter of disappointment.
              Regularly seeing the losing strands of hair or finding a bald
              patch on the scalp diminishes the inner confidence in you. As a
              result, you feel hesitant to face the people outside. However, the
              situation will soon pass away with our reliable and 100% effective
              hair fixing solutions for men. Get an instant hair growing
              artificial treatment from our expert partners that help to make
              your life happy once again.
              </p>

									</div>
									<button className="btn btn-theme mt-4">Learn More</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

      <section id="services" className="services pt-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="d-flex align-items-center h-100">
                <div className="text-left">
                  <h2 className="title text-title-services text-uppercase border-left text-left">
                    What is Hair Fixing?
                  </h2>
                  <div className="process-text">
                    <p className="mb-2 about-text">
                      Hair Fixing is a non-surgical, pain-free, and
                      cost-effective treatment for men who lose their hair
                      partially or completely. Unlike, going under the surgical
                      treatment of growing natural hair, this is a hassle-free
                      treatment for fixing a hair extension on the scalp. Our
                      real-time hairstylists do the detailed job of fixing 100%
                      human hair made extension on your scalp, where there are
                      less or no hair. This particular method is totally
                      harmless and does not let you feel any pain.
                    </p>
                    <p className="mb-2 about-text">
                      Here at Radiance Hair Studio, we use readymade and
                      customized hair extension patches to be fixed on your
                      scalp. Depending on your face type, area of baldness, type
                      of colour of hair, and other related factors, we use the
                      hair extension of the highest quality at a low cost.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 pos-rel">
              <div className="img-holder">
                <Link to="/">
                  <img
                    src="./images/hair-fixing-for-men(2).png"
                    alt="Our Mission"
                    className="img-fluid"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ServisesSlider />
      <section id="contact-section" className="contact-section pt-0">
        <div className="container">
          <header className="section-header text-center mb-4">
            <h2 className="text-title text-uppercase">
              Different Types of Hair Fixing for Men in Delhi?
            </h2>
            <p className="text-hr mt-0"></p>
          </header>
          <br></br>
          <div className="process-text">
            <p className="customizedwigs   ">
              Hair Fixing is a beneficial solution for men who suffer from
              partial or complete hair loss due to any disease or heredity
              related issue. No matter what, we offer easy and fast hair fixing
              solutions available in different types depending on the
              personalized requirements of customers.
            </p>
          </div>
        </div>
      </section>
      <section id="services" className="services pt-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="d-flex align-items-center h-100">
                <div className="text-left">
                  <h2 className="title text-title-services border-left text-uppercase text-left">
                    Hair Bonding
                  </h2>
                  <div className="process-text">
                    <p className="about-text">
                      It is one of the important types of hair fixing treatment
                      in which real human hair integration is used. Customers
                      will find naturally flowing like human hair extension
                      really bonded with their scalp. For the process, we find
                      high-quality human hair matching the criteria of your hair
                      length, colour, and wave or curl, then we fix the
                      extension on the respective scalp area using silicon glue
                      in the affected area. Shortly, the hair system merged with
                      your existing hair strands
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="img-holder">
                <Link to="/">
                  <img
                    src="./images/hair-fixing-for-men(3).png"
                    alt="Our Mission"
                    className="img-fluid"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="services" className="services pt-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="img-holder">
                <Link to="/">
                  <img
                    src="./images/hair-fixing-for-men(4).png"
                    alt="Our Mission"
                    className="img-fluid"
                  />
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="d-flex align-items-center h-100">
                <div className="text-left">
                  <h2 className="title text-title-services border-left text-uppercase text-left">
                    Hair Patch
                  </h2>
                  <div className="process-text">
                    <p className="about-text">
                      As the name implies, get a real human hair fixed patch
                      applied to your whole scalp and cover the baldness or hair
                      loss with ease. It is the most affordable hair growing
                      treatment for men who cannot afford to go through the
                      transplant process. To restore the confidence of male
                      customers, we offer this hair fixing treatment of finding
                      a particular hair patch for the bald area on your head.
                      Our experts use skin-friendly glue on the patch which is
                      then fixed on the scalp to cover up the affected area and
                      appears real to everyone’s eyes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="services" className="services pt-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="d-flex align-items-center h-100">
                <div className="text-left">
                  <h2 className="title text-title-services border-left text-uppercase text-left">
                    Hair Weaving
                  </h2>
                  <div className="process-text">
                    <p className="mb-2 about-text">
                      It is one of the best hair replacement and fixing
                      treatments available for men. Under the process, hair
                      extensions are weaved onto your scalp area to bring a
                      natural deal of thickness and fullness to the whole area.
                      Using this method, you will attain a complete hair full of
                      outlook to catch people’s attention at a glance.
                    </p>
                    <p className="about-text">
                      If you are looking for result-oriented hair fixing for men
                      in Delhi, then we are here to assist you with all possible
                      methods. We are a leading hair fixing professional expert
                      for men.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="img-holder">
                <Link to="/">
                  <img
                    src="./images/hair-fixing-for-men.png"
                    alt="Our Mission"
                    className="img-fluid"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>  
  <Appointment/>

      <Footer />
    </>
  );
};

export default Hairfixingformen;
