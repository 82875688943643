import React from "react";
import { Link } from "react-router-dom";
import ServisesSlider from "./ServisesSlider";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import "swiper/css/pagination";
import Appointment from "../Appointment";

const Volumiserhairadditions = () => {
  return (
    <>
      <Header />
      <section className="about-banner">
        <div className="container">
          <div className="banner-content">
            <h2>Volumiser Hair Additions</h2>
          </div>
        </div>
      </section>

      <section className="contact-section pb-0">
        <div className="container">
          <div className="section-header text-center mb-4">
          <h2 className="text-title text-uppercase ">
          Volumiser Hair Additions
            </h2>
            <p className="text-hr  mt-0"></p>
          </div>
          </div>
      </section>

      <section id="services" className="services pt-4">
				<div className="container">
					<div className="row">
						<div className="col-md-6 col-12">
							<div className="img-holder">
							<Link to="/"><img src="images/benefits/volumiser-hair-additions.png" alt="logo" className="img-fluid" /></Link>
							</div>
						</div>
						<div className="col-md-6 col-12">
							<div className="d-flex align-items-center h-100">
								<div className="text-left">
									<div className="process-text">
							<p className="mb-2 text-subtitle-services">
              Thinning, losing, or falling are the common scenarios of getting
              your hair outlook getting fully damaged. As a female, you always
              want that desirable outlook of thick, long, and flowing hair to
              showcase the best of your personality to the eyes of others. If
              due for some reason, you are losing some or all parts of hair on
              your head, then we are here to help you with end-to-end volumiser
              hair additions solutions. As the name implies, we are here to
              bring in more volume on your head full of 100% human hair and get
              that lost glory back to you.
              </p>
              <p className="mb-2 text-subtitle-services">
              No matter, if you are rapidly losing the hair due to genetics, any
              disease, poor diet, tension, or any other issue. We have got the
              right solution for this serious problem by providing volumiser
              hair additions as per your customized requirement and budget
              preference.
              </p>
									</div>
									<button className="btn btn-theme mt-4">Learn More</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

      <ServisesSlider />

      <section id="services" className="services pt-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="d-flex align-items-center h-100">
                <div className="text-left">
                  <h2 className="title text-title-services text-uppercase text-left">
                    What is Volumiser Hair Additions?
                  </h2>
                  <div className="process-text">
                    <p className="mb-2 about-text">
                      At Radiance Hair Studio, we care for your elegant outlook
                      and do everything to remain it for a long-lasting time.
                      Concerning the same, we offer volumiser hair additions
                      which is a non-surgical technique of adding more hair
                      volume on your scalp to complete the outlook. We offer
                      every size, texture, colour, and framework of hair
                      extensions matching your outlook and requirement to bring
                      added volume and thickness to your personal level.
                    </p>
                    <p className="mb-2 about-text">
                      The volumiser hair additions best complement the look,
                      texture, and shade of your existing hair and no one will
                      find any difference between the two. We have our expert
                      and professional team of hair stylists who are proficient
                      in taking your matching style of hair extensions and add
                      as volume to your scalp.
                    </p>
                    <p className="mb-2 about-text">
                      Be it like short or long, we provide the much-needed
                      volume of hair on your scalp to complete the outlook.
                      Tape-in and the Clip-in are the two effective ways to
                      attach the desired volume of hair extension to your scalp.
                      Thus, it is up to you to either tape-in the hair additions
                      at any point in time. On the other side, you can opt for a
                      clip-in method of adding extensions in a hassle-free
                      manner.
                    </p>
                    <p className="mb-2 about-text">
                      One of the best things about volumiser hair additions in
                      Delhi is easy to maintain, non-surgical, non-invasive,
                      100% human hair, lustre, dense, no side effects, and
                      perfect to use for any occasion you like. So, it’s time to
                      receive the most useful and much-needed hair volume
                      addition service from us at a cost-effective price.
                    </p>
                  </div>
                  {/* <button className="btn btn-theme mt-4">Learn More</button> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="img-holder image-top">
                <Link to="/">
                  <img
                    src="images/volumiser-hair-additions.png"
                    alt="Our Mission"
                    className="img-fluid"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

<Appointment/>

      {/* <section className="appointment-banner">
        <div className="container-fluid header-section style-3">
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-6 mb-5 mb-lg-0">
                <div className="mb-4">
                  <h2 className="title text-white text-title-services hair-apponit text-uppercase border-left text-left">
                    Book your Appointment Now!
                  </h2>
                </div>
                <p className="mb-2 appointment-text">
                  Wait no more to bring back that desired outlook and appear
                  gorgeous to the eyes of onlookers. Realy on the
                  result-oriented volumiser hair additions service of our expert
                  team of partners to completely revamp your lifestyle and
                  outlook. Our team has an extensive years of experience, a
                  long-list of clientele, and an optimum level of customer
                  satisfaction in adding the desired volume of hair to women’s
                  scalps. Plus, it is a pain-free, cost-effective, and fast to
                  experience hair growing service that is sure to make your day
                  for the upcoming time in life.
                </p>
                <p className="appointment-text">
                  It’s time to make a call now and book your appointment with us
                  to receive beauty enabled volumiser hair additions treatment.
                </p>

                <div>
                  <Link to="" className="take-btn py-3 px-5 me-3">
                    Take a Hair Test
                  </Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="bg-light text-center rounded p-5">
                  <h1 className="mb-4 text-white">Book An Appointment</h1>
                  <form>
                    <div className="row g-3">
                      <div className="col-12 col-sm-12">
                        <input
                          type="text"
                          name="name"
                          className="form-control bg-white border-0"
                          placeholder="Name"
                        />
                      </div>
                      <div className="col-12 col-sm-12">
                        <input
                          type="email"
                          name="email"
                          className="form-control bg-white border-0"
                          placeholder="Email"
                        />
                      </div>
                      <div className="col-12 col-sm-12">
                        <input
                          type="number"
                          name="phone"
                          className="form-control bg-white border-0"
                          placeholder="Phone"
                        />
                      </div>
                      <div className="col-12 col-sm-12">
                        <div
                          className="date"
                          id="date"
                          data-target-input="nearest"
                        >
                          <input
                            type="date"
                            id="datepicker"
                            className="form-control bg-white border-0 datetimepicker-input"
                            placeholder="Date"
                            v-model="date"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12">
                        <input
                          type="text"
                          name="serviceneed"
                          className="form-control bg-white border-0"
                          placeholder="Service you need"
                        />
                      </div>
                      <div className="col-12 col-sm-12">
                        <textarea
                          name="anynote"
                          className="form-control bg-white border-0"
                          placeholder="Any note for us"
                        />
                      </div>
                      <div className="col-12">
                        <button className="btn w-100 py-3" type="submit">
                          Make An Appointment
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <Footer />
    </>
  );
};

export default Volumiserhairadditions;
