import { Link } from "react-router-dom";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
const HairReplacement = () => {
  return (
    <>
      <section id="hair-replacement" className="hair-replacement pb-0">
   
        <div className="container">
          <header className="section-header text-center mb-4 wow fadeInUp"  data-wow-duration="2s">
            <h2 className="text-title text-uppercase wow animate__animated animate__slideInUp">WHY HAIR REPLACEMENT?</h2>
            <p className="text-subtitle pb-0 wow animate__animated animate__slideInUp">
            With a focus on natural-looking hair that blends seamlessly with your own, we're committed to helping you feel confident and beautiful. 
            </p>
            <p className="text-hr mt-0"></p>
          </header>
          <br></br>
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="img-holder">
                <Link to="/">
                  <img
                    src="images/hair-replacement/left.png"
                    alt="logo"
                    className="img-fluid wow animate__animated animate__slideInLeft"
                  />
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-12 pos-rel wow animate__animated animate__slideInRight">
              {/* <div className="img-holder1 ">
								
                                <Link to="/"><img src="images/hair-replacement/right.png" alt="logo" className="img-fluid" /></Link>
								
							</div> */}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                }}
              >
                <ReactCompareSlider
                  boundsPadding={0}
                  changePositionOnHover
                  itemOne={
                    <ReactCompareSliderImage
                      alt="Image one"
                      src="https://lapiel.in/wp-content/uploads/2023/03/LaPiel_Hair_Transplantation_Before.png"
                    />
                  }
                  itemTwo={
                    <ReactCompareSliderImage
                      alt="Image two"
                      src="https://lapiel.in/wp-content/uploads/2023/03/LaPiel_Hair_Transplantation_After.png"
                    />
                  }
                  position={50}
                  style={{
                    flexGrow: 1,
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HairReplacement;
