import { Link } from "react-router-dom";
const Mission = () => {
  return (
    <>
      <section id="services" className="services pt-0">
        <div className="container">
          <div className="row">

            <div className="col-md-6 col-12">
              <div className="d-flex align-items-center h-100">
                <div className="text-left">
                  <h2 className="title text-title-services text-uppercase border-left text-left wow animate__animated animate__zoomIn">
                    Our Mission
                  </h2>
                  <div className="process-text">
                    <p className="mb-2 about-text wow animate__animated animate__slideInLeft">
                    We at Radiance Hair Studio are on a result-oriented mission to help women and men deal with problematic hair loss issues with viable & affordable solutions. To assist the same, our team of experts comes up with fashionable and technically advanced hair growth and replacement solutions to serve clients at every single stage. Moreover, we always strive to be innovative, motivate, and inspire our clients to acquire much-needed hair growing solutions to maintain an attractive outlook all the time.
                    </p>
                    <p className="mb-2 about-text wow animate__animated animate__slideInLeft">We are here to make you appear attractive and confident in your outlook. Be ready to wear shiny looking natural hair and compliment your personality to an optimum extent. </p>
                  </div>
                  {/* <button className="btn btn-theme mt-4">Learn More</button> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="img-holder">
                <Link to="/">
                  <img
                    src="images/about/about2.png"
                    alt="Our Mission"
                    className="img-fluid wow animate__animated animate__backInRight"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Mission;
