import { Autoplay, Navigation, Pagination, } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import React  from "react";
import { Link } from "react-router-dom";
import "swiper/css/pagination";


const flagSlider = [
    {
      imgUrl: "images/about/hair-man.png  ",
      imgAlt: "Hair Wigs for Men",
      title: "Hair Wigs for Men",

    },
    {
      imgUrl: "images/about/hair-woman.png",
      imgAlt: "Hair Wigs for Women",
      title: "Hair Wigs for Women",

    },
    {
      imgUrl: "images/about/hair-replacement.png",
      imgAlt: "Hair Replacement",
      title: "Hair Replacement",

    },
    {
      imgUrl: "images/about/non-surgical-hair-replacement.png",
      imgAlt: "Non-Surgical Hair Replacement",
      title: "Non-Surgical Hair Replacement",

    },
    {
        imgUrl: "images/about/hair-extension.png",
        imgAlt: "Human Hair Extension",
        title: "Human Hair Extension",
  
      },
      {
        imgUrl: "images/about/hair-bonding.png",
        imgAlt: "Hair Bonding",
        title: "Hair Bonding",
  
      },
      {
        imgUrl: "images/about/hair-weaving.png",
        imgAlt: "Hair Weaving",
        title: "Hair Weaving",
  
      },
      {
        imgUrl: "images/about/hair-clipping.png",
        imgAlt: "Tape In & Clip In",
        title: "Tape In & Clip In",
  
      },
      {
        imgUrl: "images/about/hari-cancer-patients.png",
        imgAlt: "Hair Wigs for Cancer Patients",
        title: "Hair Wigs for Cancer Patients",
  
      },
      {
        imgUrl: "images/about/hair-fixing.png",
        imgAlt: "Hair Fixing",
        title: "Hair Fixing",
  
      },
      {
        imgUrl: "images/testimonial/testimonial4.jpeg",
        imgAlt: "Volumizer Hair Additions",
        title   : "Volumizer Hair Additions",
  
      },
      

  ];

// const AboutSection = () => {

const AboutSection: React.FC = () => {


  return (
    <> 
        <section className="about-banner">
        <div className="container">
          <div className="banner-content">
            <h2 className="wow fadeInLeft" data-wow-duration="1s">About Us</h2>
          </div>
          <div className="sc_layouts_title_breadcrumbs wow fadeInLeft">
            <div className="breadcrumbs">
            <a className="breadcrumbs_item home" href="/radiance/">Home</a>
            <span className="breadcrumbs_delimiter"></span>
            <span className="breadcrumbs_item current">About Us</span>
          </div>
        </div>
        </div>
      </section>
      <section id="services" className="services">
				<div className="container">
					<div className="row">
						<div className="col-md-6 col-12">
							<div className="img-holder">
							<Link to="/"><img src="images/about/Group66.png" alt="logo" className="img-fluid wow animate__animated animate__slideInLeft" /></Link>
							</div>
						</div>
						<div className="col-md-6 col-12">
							<div className="d-flex align-items-center h-100">
								<div className="text-left">
									<h2 className="title text-title-services text-uppercase border-left text-left wow animate__animated animate__zoomIn">Radiance About</h2>
									<div className="process-text">
										<p className="mb-2 text-subtitle-services animate__animated animate__slideInRight" data-wow-duration="2s">
                    Losing one’s hair is a matter of careful consideration, as it
              impacts the overall personality. Be it like men or women, hair
              loss or hair fall is a serious issue that would hamper the overall
              outlook, especially while outside among different people around.
              Most importantly, falling off natural hair from the head lead to
              degraded confidence in one’s life that further lead to hardships
              and demotivating lifestyle.										</p>
										<p className="mb-2 text-subtitle-services animate__animated animate__slideInRight" data-wow-duration="2s">
                    Radiance Hair Studio is available round the clock to provide
              customized hair solutions to men and women of all age groups.
              Being a pioneer leader in the hairstyling industry, we offer a
              plethora of men and women hair solutions to redefine their
              personalities to an optimum extent. Our experienced, certified,
              and dedicated staff, comprises pioneer experts in handling common
              and medical-related hair loss and hair fall problems of all people
              with ease and comfort.										</p>
										<p className="mb-2 text-subtitle-services animate__animated animate__slideInRight" data-wow-duration="2s">

                    For more than 20 years, we have been in this industry setting our
              footprint as a reliable hair solution provider in Delhi/NCR
              region. Here, we offer technically and clinically proven hair fall
              or loss related solutions meeting the expectations of clients to
              an optimum extent. By using state-of-the-art equipment and
              advanced technology, we provide hair wigs, patches, and
              replacement solutions to give you a stunning and attractive
              outlook like never before. </p>
              <p className="text-subtitle-services animate__animated animate__slideInRight" data-wow-duration="2s">
              Our team of experts fully understand your hair related issues and
              provides a viable treatment matching your expectations and budget.
              The medically-proven hair loss treatment helps clients get glossy,
              shiny, and bouncy natural hair to prevent the issue of loss or
              fall.
            </p>
									</div>
									<button className="btn btn-theme mt-4">Learn More</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>


      <section id="testmonial" className=" pt-0">
      <div className="row event-cols">
        <br></br>
        <div className="col-md-12 col-12 section about-slider">
            
          <div className="">
          <header className="section-header text-center mt-4 mb-0">
						<h2 className=" text-uppercase text-white text-title ">Hair Services </h2>
						<p className="text-subtitle pb-0 text-white">It is a long established fact that a reader will be distracted by the readable content</p>
						
					</header>
            <Swiper
            spaceBetween={20}
            slidesPerView={10}
            centeredSlides={true}
                 
			  pagination={{
				clickable: true,
			  }}
			  loop={true}
              autoplay={true}
              navigation={{
                prevEl: ".course-navi-prev",
                nextEl: ".course-navi-next",
              }}

              modules={[Autoplay, Navigation ,Pagination]}
              breakpoints={{
                0: {
                  width: 0,
                  slidesPerView: 1,
                },
                680: {
                  width: 680,
                  slidesPerView: 3,
                },
                1200: {
               
                  slidesPerView:4.80,
                },
              }}
            >
              {flagSlider.map((val, i) => (
                <SwiperSlide key={i}>
                  <div className="gtco-testimonials about-testimonial ">
             
	
                    <img
                          className=""
                          src={`${val.imgUrl}`}
                          alt={`${val.imgAlt}`}
                        />
                           <h2 className="">
                            {val.title}
                          </h2>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
 
    </>
  );
};

export default AboutSection;
