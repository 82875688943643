const Appointment = () => {
  return (
    <>
      <div className="forme" id="appointment">
  
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7">
              <div className="herg">
                <h3 className="wow fadeInUp" data-wow-duration="2s">Why choose us?</h3>
                <br></br>
                <br></br>
                <div className="row">
                  <div className="col-md-6">
                    <div className="list">
                      <ul className="hiul">
                        <li className="wow fadeInUp" data-wow-duration="2s">
                          <h6>Non-Surgical Hair Replacement</h6>
                          It's a totally non-invasive procedure, not involving
                          any surgery whatsoever, most importantly it's
                          virtually undetectable.
                        </li>
                        <li className="wow fadeInUp" data-wow-duration="2s">
                          <h6>Ready to Wear</h6>
                          You can literally walk out from the center with a full
                          head of hair about an hour after with the look you
                          desire.
                        </li>
                        <li className="wow fadeInUp" data-wow-duration="2s">
                          <h6>Affordable Price</h6>
                          The important advantage that you gain when going for
                          non-surgical hair replacement systems is it is easy
                          and cheaper than all other processes.
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="list">
                      <ul className="hiul">
                        <li className="wow fadeInUp" data-wow-duration="2s">
                          <h6>30 Days Money Back Guarantee</h6>
                          If there are any issues with your product, you can
                          raise a replacement orexchange request within 30 days
                          of purchase of the product.
                        </li>
                        <li className="wow fadeInUp" data-wow-duration="2s">
                          <h6>Maintenance</h6>
                          Non-surgical hair replacement systems are super easy
                          to maintain, Wash & blow dry hair every day. No
                          tangling, no slippage at all.
                        </li>
                        <li className="wow fadeInUp" data-wow-duration="2s">
                          <h6 className="text-white">Workout Friendly</h6>
                          You can exercise, swim, sweat, workout every day &
                          bond will never be compromised! And most important you
                          will look great too while doing it!!
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="menbership">
        <h3>Memberships</h3>
        <div className="row">
                <div className="col-md-3 col-6">

                        <img data-original="https://www.skinfinityderma.com/assets/website/images/mem1.png"
                                className="lazy" alt=""
                                src="https://www.skinfinityderma.com/assets/website/images/mem1.png" />
                </div>

                <div className="col-md-3 col-6">
                        <img data-original="https://www.skinfinityderma.com/assets/website/images/mem2.png"
                                className="lazy" alt=""
                                src="https://www.skinfinityderma.com/assets/website/images/mem2.png" />
                </div>

                <div className="col-md-3 col-6">
                        <img data-original="https://www.skinfinityderma.com/assets/website/images/mem3.png"
                                className="lazy" alt=""
                                src="https://www.skinfinityderma.com/assets/website/images/mem3.png" />
                </div>

                <div className="col-md-3 col-6">
                        <img data-original="https://www.skinfinityderma.com/assets/website/images/meme5.png"
                                className="lazy" alt=""
                                src="https://www.skinfinityderma.com/assets/website/images/meme5.png" />
                </div>
        </div>

</div> */}
            </div>

            <div className="col-md-5 hijol appointment_form wow fadeInUp" data-wow-duration="2s">
              <div className=" text-center form-w">
                <h1 className="mb-4 text-white">Book An Appointment</h1>
                <div className="hertyuik7">
                  Consulting Time : Mon-Sun 10:00 AM – 08:00 PM
                </div>
                <form>
                  <div className="row g-3">
                    <div className="col-6 col-sm-12">
                      <input
                        type="text"
                        name="name"
                        className="form-control bg-white border-0"
                        placeholder="Name"
                      />
                    </div>
                    <div className="col-6 col-sm-12">
                      <input
                        type="email"
                        name="email"
                        className="form-control bg-white border-0"
                        placeholder="Email"
                      />
                    </div>
                    <div className="col-6 col-sm-12">
                      <input
                        type="number"
                        name="phone"
                        className="form-control bg-white border-0"
                        placeholder="Phone"
                      />
                    </div>
                    <div className="col-6 col-sm-12">
                      <div
                        className="date"
                        id="date"
                        data-target-input="nearest"
                      >
                        <input
                          type="date"
                          id="datepicker"
                          className="form-control bg-white border-0 datetimepicker-input"
                          placeholder="Date"
                          v-model="date"
                        />
                      </div>
                    </div>
                    <div className="col-6 col-sm-12">
                      <input
                        type="text"
                        name="serviceneed"
                        className="form-control bg-white border-0"
                        placeholder="Service you need"
                      />
                    </div>
                    <div className="col-6 col-sm-12">
                      <textarea
                        name="anynote"
                        className="form-control bg-white border-0"
                        placeholder="Any note for us"
                      />
                    </div>
                    <div className="col-12">
                      <button className="btn buy7 w-100 py-3" type="submit">
                        Make An Appointment
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="banner_img"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Appointment;
