import { Link } from "react-router-dom";
const Benefits = () => {
    return (
        <>

<section id="benefits" className="benefits">
				<div className="container">
					<header className="section-header text-center mb-4">
						<h2 className="theme-text-title text-uppercase wow animate__animated animate__slideInLeft">Benefits</h2>
						<p className="text-subtitle pb-0 text-white wow animate__animated animate__slideInUp">Hair Transformation Made Simple: Get Perfect Hair with Our Professional Wig and Weave Services.</p>
						<p className="text-hr mt-0"> </p>
					</header><br></br>
					<div className="row">
						<div className="col-md-6 col-12">
							<div className="img-holder">
							<Link to="/"><img src="images/benefits/benefits.png" alt="logo" className="img-fluid img-fluid wow animate__animated animate__backInLeft" /></Link>
                                {/* <Link to="/"><img src="images/benefits/benefits.png" className="img-fluid" /></Link> */}
								
							
							</div>
						</div>
						<div className="col-md-6 col-12 pos-rel img-fluid wow animate__animated animate__backInRight">
							<div className="row">
								<div className="col-md-12 col-12">
									<div className="gradient-box">
										<div className="black-box"></div>
										<div className="benefit-box">
											<h4 className="mb-2">Non-Surgical Hair Replacement</h4>
											<p className="mb-0">It's a non-invasive procedure. It does not involve any surgery and is virtually undetectable.</p>
										</div>
										<div className="gradient-circle">1</div>
									</div>
								</div>
								<div className="col-md-12 col-12">
									<div className="gradient-box">
										<div className="black-box"></div>
										<div className="benefit-box">
											<h4 className="mb-2">Maintenance</h4>
											<p className="mb-0">Non-surgical hair replacement systems are super easy to maintain. Wash and blow dry hair every day. No tangling, no slippage at all.</p>
										</div>
										<div className="gradient-circle">2</div>
									</div>
								</div>
								<div className="col-md-12 col-12">
									<div className="gradient-box">
										<div className="black-box"></div>
										<div className="benefit-box">
											<h4 className="mb-2">Affordable Price</h4>
											<p className="mb-0">The most notable advantage of our product is that it is more economical and easy to use than other hair restoration processes. </p>
										</div>
										<div className="gradient-circle">3</div>
									</div>
								</div>
								<div className="col-md-12 col-12 ">
									<div className="gradient-box">
										<div className="black-box"></div>
										<div className="benefit-box">
											<h4 className="mb-2">30 Days Money Back Guarantee</h4>
											<p className="mb-0">If there are any issues with the product, you can raise a replacement or exchange request within 30 days of the purchase of the product.</p>
										</div>
										<div className="gradient-circle">4</div>
									</div>
								</div>
							</div>
                            {/* <Link to="/"><img src="images/benefits/rectangle.png" className="img-fluid rect-image1" /></Link> */}
                            <Link to="/"><img src="images/benefits/rectangle.png" alt="logo" className="img-fluid rect-image1" /></Link>
							
						</div>
					</div>
				</div>
			</section>
            </>   
    );
}
 
export default Benefits;