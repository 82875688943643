
import { Link } from "react-router-dom";

const Banner = () => {
    return (
        <>
        <div className="header-section style-3 banner-section style-4">
            <div className="container">
                <div className="banner-content">
                    <p className="desc wow fadeInUp">Enrich your Personality with 100% Natural Hair Wigs in Delhi</p>
                    <h2 className="wow fadeInUp">Welcome To Radiance Hair Studio</h2>
                    <p className="text-hr mt-0 wow fadeInUp"></p>
                    <p className="mt-3 wow fadeInUp">To empower individuals to feel confident and beautiful in their skin by providing high-quality, natural-looking wigs and hair patches that enhance their unique beauty. Radiance Hair Studio strives to create a welcoming and inclusive environment where everyone can find the perfect solution for their hair needs.</p>
               
                        <ul className="lab-ul mt-5">
                        <li><Link to="" className="btn-theme wow fadeInUp"><span>SHOP NOW</span></Link></li>
                       <li><Link to="" className="lab-btn-1 wow fadeInUp"><span>LEARN MORE</span></Link></li>
                        </ul>
                        
                  
                </div>
            </div>
            {/* <div className="text-center rounded p-5 tody-book today-section header-fixed fadeInUp ">
        
        <div className="mb-4 text-white form_head_text">CONSULT A HAIR DOCTOR TODAY</div>
        <form>
            <div className="row g-3">
                <div className="col-12 col-sm-12">
                    <input type="text" name="name" className="form-control bg-white border-0" placeholder="Name"  />
                </div>
                <div className="col-12 col-sm-12">
                    <input type="email" name="email" className="form-control bg-white border-0" placeholder="Email"  />
                </div>

                <div className="col-12">
                    <button className="btn w-100 py-3" type="submit">Book Now</button>
                </div>
            </div>
        </form>
    </div> */}
        </div>
 

        
            </>   
    );
}
 
export default Banner;