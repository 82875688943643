import React from "react";
import { Link } from "react-router-dom";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import Appointment from "../Appointment";
import ServisesSlider from "./ServisesSlider";
import "swiper/css/pagination";


const Hairbondingformen = () => {
  return (
    <>
      <Header />
      <section className="about-banner">
        <div className="container">
          <div className="banner-content">
            <h2>Hair Bonding for Men</h2>
          </div>
        </div>
      </section>

      <section className="contact-section pb-0">
        <div className="container">
          <div className="section-header text-center mb-4">
          <h2 className="text-title text-uppercase ">
          Hair Bonding for Men in Delhi
            </h2>
            <p className="text-hr  mt-0"></p>
          </div>
          </div>
      </section>

      <section id="services" className="services pt-4">
				<div className="container">
					<div className="row">
						<div className="col-md-6 col-12">
							<div className="img-holder">
							<Link to="/"><img src="images/benefits/hair-bonding-for-men.png" alt="logo" className="img-fluid" /></Link>
							</div>
						</div>
						<div className="col-md-6 col-12">
							<div className="d-flex align-items-center h-100">
								<div className="text-left">
									<div className="process-text">
							<p className="mb-2 text-subtitle-services">
              Are you slowly or rapidly losing your hair? Do you also feel less
              confidence in yourself due to a degrading personality? If yes,
              then we are here to infuse lost confidence in you by once again
              giving you that desired outlook with a hair bonding service.</p>
							<p className="mb-2 text-subtitle-services">
              Many men like you face the overgrowing hassle of losing scalp hair
              that results in bald patches or complete baldness over the head.
              To deal with the idea, we offer a revolutionized and low-cost
              treatment of hair bonding for men in Delhi and restore customers
              hair back. Hair bonding is a painless and non-surgical hair
              treatment available for men to fix a particular size and length of
              extension on the scalp with the help of professional experts.</p>
									</div>
									<button className="btn btn-theme mt-4">Learn More</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

 <ServisesSlider/>

 <section id="services" className="services pt-0">
        <div className="container">
          <div className="row">

            <div className="col-md-6 col-12">
              <div className="d-flex align-items-center h-100">
                <div className="text-left">
                  <h2 className="title text-title-services text-uppercase border-left text-left">
                    What is Hair Bonding for Men?
                  </h2>
                  <div className="process-text">
                    <p className="mb-2 about-text">
                      Hair bonding is one of the subtypes of hair fixing methods
                      in which synthetic hair is applied on the scalp of your
                      head. Our expert hairstylists come to your hair by
                      understanding your requirements firsts and then provide a
                      well-bonded solution accordingly. It is also known as the
                      hair integration that gives you natural hair on the scalp
                      and performs the day-to-day tasks while going to the
                      office, running, exercising, playing, driving, styling,
                      swimming, and more with ease and comfort.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 pos-rel">
              <div className="img-holder">
                <Link to="/">
                  <img
                    src="./images/hair-bonding-for-men-1.png "
                    alt="Our Mission"
                    className="img-fluid"
                  />
                </Link>
              </div>
           
            </div>
          </div>
        </div>
      </section>
      <section id="services" className="services pt-0">
        <div className="container">
          <div className="row">
          <div className="col-md-6 col-12">
              <div className="img-holder">
                <Link to="/">
                  <img
                    src="./images/hair-bonding-for-men-2.png "
                    alt="Our Mission"
                    className="img-fluid"
                  />
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="d-flex align-items-center h-100">
                <div className="text-left">
                  <h2 className="title text-title-services border-left text-uppercase text-left">
                    How Do We Do It?
                  </h2>
                  <div className="process-text">
                    <p className="mb-2 about-text">
                      The process of hair bonding is simple and fast. Unlike the
                      surgical hair growing treatment, hair bonding in Delhi can
                      be done in a single sitting only. Our experts start the
                      process by finding the finely curated human hair
                      resembling your current strands, colour, size, hairline,
                      wave or curl. Accordingly, a hair extension is prepared as
                      per your need that offers an ideal outlook to your
                      personality. During the sitting process, we use silicon
                      glue on the affected area of your scalp and end the
                      process with ease.
                    </p>
                    <p className="mb-2 about-text">
                      We use this method of adding new hair to your scalp using
                      a specialized technique in which skin- friendly glue is
                      applied to the head. Despite adding layers of hair to your
                      scalp, it does not hinder the natural process of growing
                      your hair. Thus, you can have long, illustrious, shiny,
                      and naturally flowing hair with hair bonding treatment.
                    </p>
                  </div>
                  {/* <button className="btn btn-theme mt-4">Learn More</button> */}
                </div>
              </div>
            </div>
   
          </div>
        </div>
      </section>

    <Appointment/>

      <Footer />
    </>
  );
};

export default Hairbondingformen;
