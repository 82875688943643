import React from "react";
import { Link } from "react-router-dom";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import ServisesSlider from "./ServisesSlider";
import Appointment from "../Appointment";
import "swiper/css/pagination";

const Humanhairextension = () => {
  return (
    <>
      <Header />
      <section className="about-banner">
        <div className="container">
          <div className="banner-content">
            <h2>Human Hair Extension</h2>
          </div>
        </div>
      </section>

      <section className="contact-section pb-0">
        <div className="container">
          <div className="section-header text-center mb-4">
          <h2 className="text-title text-uppercase ">
          Human Hair Extension for Men in Delhi for Men in Delhi
            </h2>
            <p className="text-hr  mt-0"></p>
          </div>
          </div>
      </section>
      
      <section id="services" className="services pt-4">
				<div className="container">
					<div className="row">
						<div className="col-md-6 col-12">
							<div className="img-holder">
							<Link to="/"><img src="images/benefits/human-hair-extension.png" alt="logo" className="img-fluid" /></Link>
							</div>
						</div>
						<div className="col-md-6 col-12">
							<div className="d-flex align-items-center h-100">
								<div className="text-left">
									<div className="process-text">
							<p className="mb-2 text-subtitle-services">
              Adding more volume to the existing hair on the head is no longer a
              fashionable thing for females. More and more males, look forward
              to using hair extensions adding more volume and radiance to the
              scalp and avail that desired outlook. Thus, to help males of all
              age groups, we at Radiance Hair Studio offer an end-to-end human
              hair extension for men and offer a stylish outlook at a low cost.
              </p>
              <p className="mb-2 text-subtitle-services">
              Human hair extension, as the name implies is adding more hairs to
              your existing look to bring in more bulk, capacity, and dimensions
              to your existing outlook. Even if you have less hair on the scalp,
              then also extensions help to fill up the gap and bring in a
              dashing appearance to the eyes of onlookers.
              </p>
              <p className="text-subtitle-services">
              Here, we offer you complete support and a hair growing solution to
              meet your desired requirements. Our expert hairstylists first look
              at the current condition of your hair and suggest the necessary
              extensions that will definitely give an edge to your existing
              look. Moreover, you can lay hands on a particular choice of human
              hair extension in Delhi depending on the volume of hair, colour,
              size, and style.
              </p>

									</div>
									<button className="btn btn-theme mt-4">Learn More</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

      <ServisesSlider />
      <section id="contact-section" className="contact-section pt-0">
        <div className="container">
          <header className="section-header text-center mb-4">
            <h2 className="text-title text-uppercase">
              Different Types of Human Hair Extensions for Men
            </h2>
            <p className="text-hr mt-0"></p>
          </header>
          <br></br>
          <div className="process-text">
            <p className="customizedwigs   ">
              There are several different styles of human hair extensions for
              men are available with us that help to meet the different style
              requirements of males. The most commonly preferred hair extension
              types are as follows:
            </p>
          </div>
        </div>
      </section>
      <section id="services" className="services pt-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="d-flex align-items-center h-100">
                <div className="text-left">
                  <h2 className="title text-title-services border-left text-uppercase text-left">
                    Clip-In Hair Extensions
                  </h2>
                  <div className="process-text">
                    <p className="about-text">
                      As the name implies, clips are attached at the bottom of
                      the hair strands that come with ready to use appeal. All
                      you have to do is simply clip in the extension with your
                      existing hair using clips naps that are easy to open and
                      close. 100% natural human hair is attached to the
                      extension with clips on that and offer a least permanent
                      style of extensions. This type of extension is ideal for a
                      small outdoor travel that usually takes 10-15 min of time
                      to fix on the scalp and get ready with the best outlook.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="img-holder">
                <Link to="/">
                  <img
                    src="./images/human-hair-extension(2).png "
                    alt="Our Mission"
                    className="img-fluid"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="services" className="services pt-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="img-holder">
                <Link to="/">
                  <img
                    src="./images/human-hair-extension(3).png"
                    alt="Our Mission"
                    className="img-fluid"
                  />
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="d-flex align-items-center h-100">
                <div className="text-left">
                  <h2 className="title text-title-services border-left text-uppercase text-left">
                    Tape-In Hair Extensions
                  </h2>
                  <div className="process-text">
                    <p className="about-text">
                      It is a semi-permanent hair growing solution for men that
                      demands the complete assistance of a professional
                      hairstylist. The extensions are pre-taped and then glued
                      to either side of your hair. Only an expert hairstylist
                      can apply for this extension on your head with a perfect
                      alignment and applying heat to your roots and let the
                      extension be ideally fixed. It usually takes 40 min to 1
                      hour of time to fix up the extension and can be reused
                      again.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="services" className="services pt-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="d-flex align-items-center h-100">
                <div className="text-left">
                  <h2 className="title text-title-services border-left text-uppercase text-left">
                    Sew-In Hair Extensions
                  </h2>
                  <div className="process-text">
                    <p className="mb-2 about-text">
                      It is also called weave hair extension for men. This type
                      of extension is applied by braiding the natural hair into
                      cornrows first. The hairstylist use a needle and thread to
                      sew the weave into the cornrow. The extension can only be
                      applied with help of an expert and offer a thick hair
                      outlook. It generally takes hours of time to weave the
                      application perfectly and offer a comfortable and not so
                      heavy outlook.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="img-holder">
                <Link to="/">
                  <img
                    src="./images/human-hair-extension.png"
                    alt="Our Mission"
                    className="img-fluid"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="contact-section" className="contact-section pt-0">
        <div className="container">
          <header className="section-header text-center mb-4">
            <h2 className="text-title text-uppercase">
              Know More About Human Hair Extension
            </h2>
            <p className="text-hr  mt-0"></p>
          </header>
          <br></br>
          <div className="process-text">
            <p className="customizedwigs">
              Discuss your requirements of adding more hair volume to the head
              and wear the look that you desire. Get free consultation regarding
              human hair extension, choose an ideal type of extension, and
              undergo the treatment as per your budget. Here at Radiance Hair
              Studio, our expert hair stylists provide you with 100% personal
              attention and advice to carry a stylish look that you long for.
            </p>
          </div>
        </div>
      </section>

<Appointment/>
      <Footer />
    </>
  );
};

export default Humanhairextension;
