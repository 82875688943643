import { Autoplay, Navigation, Pagination, } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";

const flagSlider = [
    {
      imgUrl: "images/about/hair-man.png  ",
      imgAlt: "Hair Wigs for Men",
      title: "Hair Wigs for Men",

    },
    {
      imgUrl: "images/about/hair-woman.png",
      imgAlt: "Hair Wigs for Women",
      title: "Hair Wigs for Women",

    },
    {
      imgUrl: "images/about/hair-replacement.png",
      imgAlt: "Hair Replacement",
      title: "Hair Replacement",

    },
    {
      imgUrl: "images/about/non-surgical-hair-replacement.png",
      imgAlt: "Non-Surgical Hair Replacement",
      title: "Non-Surgical Hair Replacement",

    },
    {
        imgUrl: "images/about/hair-extension.png",
        imgAlt: "Human Hair Extension",
        title: "Human Hair Extension",
  
      },
      {
        imgUrl: "images/about/hair-bonding.png",
        imgAlt: "Hair Bonding",
        title: "Hair Bonding",
  
      },
      {
        imgUrl: "images/about/hair-weaving.png",
        imgAlt: "Hair Weaving",
        title: "Hair Weaving",
  
      },
      {
        imgUrl: "images/about/hair-clipping.png",
        imgAlt: "Tape In & Clip In",
        title: "Tape In & Clip In",
  
      },
      {
        imgUrl: "images/about/hari-cancer-patients.png",
        imgAlt: "Hair Wigs for Cancer Patients",
        title: "Hair Wigs for Cancer Patients",
  
      },
      {
        imgUrl: "images/about/hair-fixing.png",
        imgAlt: "Hair Fixing",
        title: "Hair Fixing",
  
      },
      {
        imgUrl: "images/testimonial/testimonial4.jpeg",
        imgAlt: "Volumizer Hair Additions",
        title   : "Volumizer Hair Additions",
  
      },
      

  ];

const ServisesSlider = () => {
  return (
    <>


<section id="testmonial" className=" pt-0">
      <div className="row event-cols">
        <br></br>
        <div className="col-md-12 col-12 section about-slider">
            
          <div className="">
          <header className="section-header text-center mt-4 mb-0">
						<h2 className=" text-uppercase text-white text-title">Hair Services </h2>
						<p className="text-subtitle pb-0 text-white">It is a long established fact that a reader will be distracted by the readable content</p>
						
					</header>
            <Swiper
            spaceBetween={20}
            slidesPerView={10}
            centeredSlides={true}
                 
			  pagination={{
				clickable: true,
			  }}
			  loop={true}
              autoplay={true}
              navigation={{
                prevEl: ".course-navi-prev",
                nextEl: ".course-navi-next",
              }}

              modules={[Autoplay, Navigation ,Pagination]}
              breakpoints={{
                0: {
                  width: 0,
                  slidesPerView: 1,
                },
                680: {
                  width: 680,
                  slidesPerView: 3,
                },
                1200: {

                  slidesPerView:4.80,
                },
              }}
            >
              {flagSlider.map((val, i) => (
                <SwiperSlide key={i}>
                  <div className="gtco-testimonials about-testimonial ">
             
	
                    <img
                          className=""
                          src={`${val.imgUrl}`}
                          alt={`${val.imgAlt}`}
                        />
                           <h2 className="">
                            {val.title}
                          </h2>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default ServisesSlider;
