import React from "react";
import Header from "./../layout/header";
import Footer from "./../layout/footer";
import { Link } from "react-router-dom";

const ContactUs: React.FC = () => {

  return (
    <>
<Header />
<section className="about-banner">
        <div className="container">
          <div className="banner-content">
            <h2>Contact Us</h2>
          </div>
        </div>
      </section>
      <div className="contact_us_6">
  <div className="responsive-container-block container">
    <form className="form-box">
      <div className="container-block form-wrapper">
        <div className="mob-text">
          <p className="text-blk contactus-head">
            Get in Touch
          </p>
     
        </div>
        <div className="responsive-container-block" id="i2cbk">
          <div className="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12" id="i10mt-3">
            <p className="text-blk input-title">
              FIRST NAME
            </p>
            <input className="input" id="ijowk-3" name="FirstName" placeholder="Please enter first name..."/>
          </div>
          <div className="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12" id="ip1yp">
            <p className="text-blk input-title">
              EMAIL
            </p>
            <input className="input" id="ipmgh-3" name="Email" placeholder="Please enter email..."/>
          </div>
          <div className="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12" id="ih9wi">
            <p className="text-blk input-title">
              PHONE NUMBER
            </p>
            <input className="input" id="imgis-3" name="PhoneNumber" placeholder="Please enter phone number..."/>
          </div>
          <div className="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12" id="i634i-3">
            <p className="text-blk input-title">
              WHAT DO YOU HAVE IN MIND ?
            </p>
            <textarea className="textinput" id="i5vyy-3" placeholder="Please enter query..."></textarea>
          </div>
        </div>
        <button className="submit-btn" id="w-c-s-bgc_p-1-dm-id-2">
          Submit
        </button>
      </div>
    </form>
    <div className="responsive-cell-block wk-desk-7 wk-ipadp-12 wk-tab-12 wk-mobile-12" id="i772w">
      <div className="map-part">
        <p className="text-blk map-contactus-head mb-3" id="w-c-s-fc_p-1-dm-id">
          Reach us at
        </p>						
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="eamil-col">
            <h5 className="">EMAIL US</h5>
            <p><a href="mailto:contact@radiancehairstudio.com"> contact@radiancehairstudio.com </a></p>
          </div>
				</div>
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="eamil-col">
            <h5 className="">Contact</h5>
            <p><strong>Call : </strong><a href="tel:+91-99585-85649">+91-99585-85649</a> </p>
            <p><strong>Call : </strong><a href="tel:+ 084487 21457">+ 084487 21457</a> </p>
          </div>
				</div>

        <div className="social-media-links mob">
          <a className="social-icon-link" href="#" id="ix94i-2-2">
            <img className="link-img image-block" src="images/icon/twitter.png"/>
          </a>
          <a className="social-icon-link" href="#" id="itixd">
            <img className="link-img image-block" src="images/icon/fb.png"/>
          </a>
          <a className="social-icon-link" href="#" id="izxvt">
            <img className="link-img image-block" src="images/icon/google.png"/>
          </a>
          <a className="social-icon-link" href="#" id="izldf-2-2">
            <img className="link-img image-block" src="images/icon/instagram.png"/>
          </a>
        </div>
        <div className="row">
						<div className="col-xl-6 col-lg-6 col-md-12">
						<div className="map-wrap">
								<div className="mapouter">
									<div className="gmap_canvas">
										<iframe width="100%" height="70%" id="gmap_canvas" src="https://maps.google.com/maps?q=radiance hair and skin clinic B-311 CR park main road block B chittranjan park delhi&t=&z=10&ie=UTF8&iwloc=&output=embed"></iframe>
									</div>
								</div>
							</div>
							<div className="border-wrap">
								<div className="address-wrap">
									<div className="row">
										<div className="col-xl-12 col-lg-12 col-md-12">
											<div className="address-col contact-map mt-2">
												<h4>OUR ADDRESS</h4>
												<p>A-66, 3rd Floor, Beside Metro Station Gate No 5, Block A, Rajouri Garden, New Delhi, Delhi 110027</p>
												{/* <p className="mb-0"></p> */}
											</div>
										</div>
								
					
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12">
						<div className="map-wrap">
								<div className="mapouter">
									<div className="gmap_canvas">
										<iframe width="100%" height="70%" id="gmap_canvas" src="https://maps.google.com/maps?q=radiance hair and skin clinic B-311 CR park main road block B chittranjan park delhi&t=&z=10&ie=UTF8&iwloc=&output=embed"></iframe>
									</div>
								</div>
							</div>
							<div className="border-wrap">
								<div className="address-wrap">
									<div className="row">
										<div className="col-xl-12 col-lg-12 col-md-12">
											<div className="address-col contact-map mt-2">
												<h4>OUR ADDRESS</h4>
												<p className="mb-0">B-311, Basement, CR Park Main Road, Chittaranjan Park, </p>
												<p className="mb-0">New Delhi, Delhi 110019</p>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
      </div>
    </div>
  </div>
</div>
<Footer />

    </>
  );
};

export default ContactUs;
