import { Link } from "react-router-dom";

const Reviews = () => {
    
    return (
        <>
<section id="reviews" className="reviews-wrap pb-5">
				<div className="container">
					<header className="section-header text-center mb-4">
						<h2 className="text-title text-uppercase wow animate__animated animate__slideInUp">Google Reviews</h2>
						<p className="text-subtitle pb-0 wow animate__animated animate__slideInUp">Experience hair that shines from within at Radiance Hair Studio</p>
						<p className="text-hr mt-0"></p>
					</header><br></br>
					<div className="row">
						<div className="col-md-6 col-12">
							<div className="img-holder">
								
                            <Link to="/"><img src="images/reviews/left-img.png" alt="1000X1000" className="img-fluid wow animate__animated animate__slideInLeft" /></Link>
								
							</div>
						</div>
						<div className="col-md-6 col-12">
							<div className="row">
								<div className="col-md-12 col-12 wow animate__animated animate__slideInRight" data-wow-duration="2s">
									<div className="review-box">
										<i className="fa fa-ellipsis-h" aria-hidden="true"></i>
										<div className="rating">
											<span className="fa fa-star" aria-hidden="true"></span>
											<span className="fa fa-star" aria-hidden="true"></span>
											<span className="fa fa-star" aria-hidden="true"></span>
											<span className="fa fa-star" aria-hidden="true"></span>
											<span className="fa fa-star" aria-hidden="true"></span>
										</div>
										<h4 className="mb-2">Rishi Arora</h4>
										<p className="mb-0">Well!! It was my first visit to Radiance Hair Studio, I find about them on the Internet ... I was looking for Good Hair Bonding solutions for a long time and visited most of the popular studios in City but I felt some of them are too costly if the quality is good and others are just a useless one. But, the price and quality of the product of Radiance offering very good and competitive. They have very good Technicians and I am satisfied with the demo with different hairstyles they have shown. I'm Completely satisfied with Radiance.</p>
									</div>
								</div>
								<div className="col-md-12 col-12 wow animate__animated animate__slideInRight" data-wow-duration="2s">
									<div className="review-box">
										<i className="fa fa-ellipsis-h" aria-hidden="true"></i>
										<div className="rating">
											<span className="fa fa-star" aria-hidden="true"></span>
											<span className="fa fa-star" aria-hidden="true"></span>
											<span className="fa fa-star" aria-hidden="true"></span>
											<span className="fa fa-star" aria-hidden="true"></span>
											<span className="fa fa-star" aria-hidden="true"></span>
										</div>
										<h4 className="mb-2">Vikek</h4>
										<p className="mb-0">Radiance Hair Studio was recommended to me by a friend of a friend. I was a bit nervous to go through. However, I am so thankful I visited Radiance. The team there made me look and feel like me again. I can’t thank them enough, and I would highly recommend them to anyone who is experiencing hair loss or Need Hair Replacement System.</p>
									</div>
								</div>
								<div className="col-md-12 col-12 wow animate__animated animate__slideInRight" data-wow-duration="2s">
									<div className="review-box">
										<i className="fa fa-ellipsis-h" aria-hidden="true"></i>
										<div className="rating">
											<span className="fa fa-star" aria-hidden="true"></span>
											<span className="fa fa-star" aria-hidden="true"></span>
											<span className="fa fa-star" aria-hidden="true"></span>
											<span className="fa fa-star" aria-hidden="true"></span>
											<span className="fa fa-star" aria-hidden="true"></span>
										</div>
										<h4 className="mb-2">Anuj</h4>
										<p className="mb-0">It has always been nice experience with radiance from day 1. Gufran is quite honest, hardworking n an expert to give you new looks which redefines your personality overall. One feedback, start taking appointments in a timely manner so that one doesn't have to wait much. Cheers Good luck</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
        
            </>   
    );
    
}
 
 export default Reviews;

