import React from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Link } from "react-router-dom";
import { Tabs } from "antd";

const TermsAndConditions: React.FC = () => {
  return (
    <>
      <Header />
      <section className="about-banner">
        <div className="container">
          <div className="banner-content">
            <h2 className="wow fadeInLeft" data-wow-duration="1s">
              Terms & Conditions
            </h2>
          </div>
          <div className="sc_layouts_title_breadcrumbs wow fadeInLeft">
            <div className="breadcrumbs">
              <a className="breadcrumbs_item home" href="/radiance/">
                Home
              </a>
              <span className="breadcrumbs_delimiter"></span>
              <span className="breadcrumbs_item current">
                Terms & Conditions
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="privacy-section pb-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-xl-12">
              <div className="w-full privacy">
                <div className="content-area">
                  <header className="entry-header">
                    <div className="d-flex align-items-center text-white">
                      <div>Terms & Conditions</div>
                      <div className="ml-auto align-items-center">
                        <div className="dl">Last updated : 28 August 2023</div>
                      </div>
                    </div>
                  </header>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="hair-replacement" className="term-condition pt-5">
        <div className="container">
          <Tabs defaultActiveKey="tab1" tabPosition="left">
            <Tabs.TabPane tab="AGREEMENT TO TERMS" key="tab1">
              <div>
                <h5 className="pb-4">AGREEMENT TO TERMS</h5>
                <p className="wow animate__animated animate__slideInRight">
                  These Terms of Use constitute a legally binding agreement made
                  between you, whether personally or on behalf of an entity
                  ("You") and Radiance Hair Studio (" Company ", "we", "us", or
                  "our"), concerning your access to and use of the
                  <Link to="http://www.radiancehairstudio.com" target="blank">
                    http://www.radiancehairstudio.com{" "}
                  </Link>{" "}
                  website as well as any other media form, media channel, mobile
                  website or mobile application related, linked, or otherwise
                  connected thereto (collectively, the "Site"). We are
                  registered in India and have our registered office at A-66,
                  3rd Floor, Beside Metro Station Gate No 5, Block A, Rajouri
                  Garden , New Delhi , Delhi 110027 . You agree that by
                  accessing the Site, you have read, understood, and agree to be
                  bound by all of these Terms of Use . IF YOU DO NOT AGREE WITH
                  ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED
                  FROM USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.
                </p>
                <p className="wow animate__animated animate__slideInRight">
                  Supplemental terms and conditions or documents that may be
                  posted on the Site from time to time are hereby expressly
                  incorporated herein by reference. We reserve the right, in our
                  sole discretion, to make changes or modifications to these
                  Terms of Use at any time and for any reason. We will alert you
                  about any changes by updating the "Last updated" date of these
                  Terms of Use, and you waive any right to receive specific
                  notice of each such change. Please ensure that you check the
                  applicable Terms every time you use our Site so that you
                  understand which Terms apply. You will be subject to, and will
                  be deemed to have been made aware of and to have accepted, the
                  changes in any revised Terms of Use by your continued use of
                  the Site after the date such revised Terms of Use are posted.
                </p>
                <p className="wow animate__animated animate__slideInRight">
                  The information provided on the Site is not intended for
                  distribution to or use by any person or entity in any
                  jurisdiction or country where such distribution or use would
                  be contrary to law or regulation or which would subject us to
                  any registration requirement within such jurisdiction or
                  country. Accordingly, those persons who choose to access the
                  Site from other locations do so on their own initiative and
                  are solely responsible for compliance with local laws, if and
                  to the extent local laws are applicable.
                </p>
                <p className="wow animate__animated animate__slideInRight">
                  All users who are minors in the jurisdiction in which they
                  reside (generally under the age of 18) must have the
                  permission of, and be directly supervised by, their parent or
                  guardian to use the Site. If you are a minor, you must have
                  your parent or guardian read and agree to these Terms of Use
                  prior to you using the Site.
                </p>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="INTELLECTUAL PROPERTY RIGHTS" key="tab2">
              <div>
              <h5 className="pb-4">INTELLECTUAL PROPERTY RIGHTS</h5>
                  <p className="wow animate__animated animate__slideInRight">
                    Unless otherwise indicated, the Site is our proprietary
                    property and all source code, databases, functionality,
                    software, website designs, audio, video, text, photographs,
                    and graphics on the Site (collectively, the "Content") and
                    the trademarks, service marks, and logos contained therein
                    (the "Marks") are owned or controlled by us or licensed to
                    us, and are protected by copyright and trademark laws and
                    various other intellectual property rights and unfair
                    competition laws of the Indian copyright laws, and
                    international conventions. The Content and the Marks are
                    provided on the Site "AS IS" for your information and
                    personal use only. Except as expressly provided in these
                    Terms of Use, no part of the Site and no Content or Marks
                    may be copied, reproduced, aggregated, republished,
                    uploaded, posted, publicly displayed, encoded, translated,
                    transmitted, distributed, sold, licensed, or otherwise
                    exploited for any commercial purpose whatsoever, without our
                    express prior written permission.
                  </p>
                  <p className="wow animate__animated animate__slideInRight">
                    Provided that you are eligible to use the Site, you are
                    granted a limited license to access and use the Site and to
                    download or print a copy of any portion of the Content to
                    which you have properly gained access solely for your
                    personal, non-commercial use. We reserve all rights not
                    expressly granted to you in and to the Site, the Content,
                    and the Marks.
                  </p>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="USER REPRESENTATIONS" key="tab3">
              <div>
              <h5 className="pb-4">USER REPRESENTATIONS</h5>
                  <p className="wow animate__animated animate__slideInRight">
                    <strong>
                      By using the Site, you represent and warrant that:
                    </strong>
                  </p>
                  <ul className="wow fadeInUp" data-wow-duration="1s">
                    <li>
                      you have the legal capacity and you agree to comply with
                      these Terms of Use.
                    </li>
                    <li>
                      ou are not a minor in the jurisdiction in which you reside
                      , or if a minor, you have received parental permission to
                      use the Site.
                    </li>
                    <li>
                      you will not access the Site through automated or
                      non-human means, whether through a bot, script or
                      otherwise.
                    </li>
                    <li>
                      you will not use the Site for any illegal or unauthorized
                      purpose.
                    </li>
                    <li>
                      your use of the Site will not violate any applicable law
                      or regulation.
                    </li>
                  </ul>
                  <p className="mt-3 wow animate__animated animate__slideInRight">
                    If you provide any information that is untrue, inaccurate,
                    not current, or incomplete, we have the right to suspend or
                    terminate your account and refuse any and all current or
                    future use of the Site (or any portion thereof).
                  </p>
                
               
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="FEES AND PAYMENT" key="tab4">
              <div>
              <h5 className="pb-4">FEES AND PAYMENT</h5>
                  <p className="wow animate__animated animate__slideInRight">
                    <strong>We accept the following forms of payment:</strong>
                  </p>
                  <ul className="wow fadeInLeft" data-wow-duration="1s">
                    <li>Visa</li>
                    <li>Mastercard</li>
                    <li>American Express</li>
                    <li>Cash</li>
                    <li>UPI</li>
                    <li>e-Wallet</li>
                    <li>Bank Transfer</li>
                  </ul>
                  <p className="pt-4 wow animate__animated animate__slideInRight">
                    You may be required to purchase or pay a fee to access some
                    of our services. You agree to provide current, complete, and
                    accurate purchase and account information for all purchases
                    made via the Site. You further agree to promptly update
                    account and payment information, including email address,
                    payment method, and payment card expiration date, so that we
                    can complete your transactions and contact you as needed. We
                    bill you through an online billing account for purchases
                    made via the Site. Sales tax will be added to the price of
                    purchases as deemed required by us. We may change prices at
                    any time. All payments shall be in Indian Rupee.
                  </p>
                  <p className="wow animate__animated animate__slideInRight">
                    You agree to pay all charges or fees at the prices then in
                    effect for your purchases, and you authorize us to charge
                    your chosen payment provider for any such amounts upon
                    making your purchase. If your purchase is subject to
                    recurring charges, then you consent to our charging your
                    payment method on a recurring basis without requiring your
                    prior approval for each recurring charge, until you notify
                    us of your cancellation.
                  </p>
                  <p className="wow animate__animated animate__slideInRight">
                    We reserve the right to correct any errors or mistakes in
                    pricing, even if we have already requested or received
                    payment. We also reserve the right to refuse any order
                    placed through the Site.
                  </p>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="CANCELLATION" key="tab5">
              <div>
              <h5 className="pb-4">CANCELLATION</h5>
                  <p className="wow animate__animated animate__slideInRight">
                    All purchases of products and services are non-refundable.
                    You can cancel your subscription at any time by contacting
                    us using the contact information provided below . Your
                    cancellation will take effect at the end of the current paid
                    term.
                  </p>
                  <p className="wow animate__animated animate__slideInRight">
                    If you are unsatisfied with our services:<br></br>
                    <br></br>
                    <Link to="mailto:contact@radiancehairstudio.com">
                      <b>Email:</b> contact@radiancehairstudio.com
                    </Link>
                    <br></br>
                    <br></br>
                    <Link to="tel:+91-99585-85649">
                      <b>call Us:</b> +91-99585-85649
                    </Link>
                  </p>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="RULES & REGULATIONS" key="tab6">
              <div>
              <h5 className="pb-4">RULES & REGULATIONS</h5>
                  <p className="pt-4 wow animate__animated animate__slideInRight">
                    I acknowledge that hair systems are very sensitive and
                    different from intact human hair. I have been informed of
                    the daily maintenance procedure. I will follow the
                    maintenance procedure to keep my Hair System in the best
                    condition possible. I am fully aware and responsible that
                    any mishandling could potentially damage the hair system.
                  </p>
                  <p className="pt-4 wow animate__animated animate__slideInRight">
                    I acknowledge that this service is final after the
                    application. Any changes to the style after application will
                    be charged additionally by the institution.
                  </p>
                  <p className="pt-4 wow animate__animated animate__slideInRight">
                    I acknowledge that I have inspected and approved the hair
                    system that is to be installed in my hair. If I decide not
                    to keep the hair system, I am fully responsible for the
                    total payment of services rendered. I also understand the
                    explanations of the entire procedure, and I am aware that
                    with proper care on my part.
                  </p>
                  <p className="pt-4 wow animate__animated animate__slideInRight">
                    I understand that if an allergic reaction occurs, I will not
                    hold my technician or stylist at fault. The charge for the
                    removal of the hair system is not included in the original
                    fee.
                  </p>
                  <p className="pt-4 wow animate__animated animate__slideInRight">
                    I am aware that the Non-surgical hair replacement process is
                    only a cosmetic procedure and have been involved in the
                    decision of making about the choice of treatment. I have
                    been explained the pros and cons of undergoing the
                    procedure.
                  </p>
                  <p className="pt-4 wow animate__animated animate__slideInRight">
                    I understand that although every effort will be made to
                    achieve optimal results, many variables exist and therefore
                    optimal results cannot always be guaranteed.
                  </p>
                  <p className="pt-4 wow animate__animated animate__slideInRight">
                    I acknowledge & hereby accepting that I will not claim any
                    refund of the amount deposited with the institution at the
                    time of admission in treatment/course opted by me as the
                    said amount is not refundable in any condition.
                  </p>
                  <p className="pt-4 wow animate__animated animate__slideInRight">Any disputes would be subject to Delhi jurisdiction.</p>
                  <p className="pt-4 wow animate__animated animate__slideInRight">
                    I acknowledged that I am physically completely fit and
                    having sound health. I do not suffer from any chronic
                    illness that could be harmful to a particular treatment/
                    course, For which everything has already been explained to
                    me.
                  </p>
                </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="PROHIBITED ACTIVITIES" key="tab7">
              <div>
              <h5 className="pb-4">PROHIBITED ACTIVITIES</h5>
                  <p className="pt-4 wow animate__animated animate__slideInRight">
                    You may not access or use the Site for any purpose other
                    than that for which we make the Site available. The Site may
                    not be used in connection with any commercial endeavors
                    except those that are specifically endorsed or approved by
                    us.
                  </p>
                  <p className="pt-4 wow animate__animated animate__slideInRight">
                    <strong>As a user of the Site, you agree not to:</strong>
                  </p>

                  <ul>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Systematically retrieve data or other content from the
                      Site to create or compile, directly or indirectly, a
                      collection, compilation, database, or directory without
                      written permission from us.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Trick, defraud, or mislead us and other users, especially
                      in any attempt to learn sensitive account information such
                      as user passwords.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Circumvent, disable, or otherwise interfere with
                      security-related features of the Site, including features
                      that prevent or restrict the use or copying of any Content
                      or enforce limitations on the use of the Site and/or the
                      Content contained therein.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Disparage, tarnish, or otherwise harm, in our opinion, us
                      and/or the Site.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Use any information obtained from the Site in order to
                      harass, abuse, or harm another person.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Make improper use of our support services or submit false
                      reports of abuse or misconduct.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Use the Site in a manner inconsistent with any applicable
                      laws or regulations.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Engage in unauthorized framing of or linking to the Site.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Upload or transmit (or attempt to upload or to transmit)
                      viruses, Trojan horses, or other material, including
                      excessive use of capital letters and spamming (continuous
                      posting of repetitive text), that interferes with any
                      party's uninterrupted use and enjoyment of the Site or
                      modifies, impairs, disrupts, alters, or interferes with
                      the use, features, functions, operation, or maintenance of
                      the Site.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Engage in any automated use of the system, such as using
                      scripts to send comments or messages, or using any data
                      mining, robots, or similar data gathering and extraction
                      tools.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Delete the copyright or other proprietary rights notice
                      from any Content.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Attempt to impersonate another user or person or use the
                      username of another user.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Upload or transmit (or attempt to upload or to transmit)
                      any material that acts as a passive or active information
                      collection or transmission mechanism, including without
                      limitation, clear graphics interchange formats ("gifs"),
                      1x1 pixels, web bugs, cookies, or other similar devices
                      (sometimes referred to as "spyware" or "passive collection
                      mechanisms" or "pcms").
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Interfere with, disrupt, or create an undue burden on the
                      Site or the networks or services connected to the Site.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Harass, annoy, intimidate, or threaten any of our
                      employees or agents engaged in providing any portion of
                      the Site to you.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Attempt to bypass any measures of the Site designed to
                      prevent or restrict access to the Site, or any portion of
                      the Site.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Copy or adapt the Site's software, including but not
                      limited to Flash, PHP, HTML, JavaScript, or other code.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Except as permitted by applicable law, decipher,
                      decompile, disassemble, or reverse engineer any of the
                      software comprising or in any way making up a part of the
                      Site.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Except as may be the result of standard search engine or
                      Internet browser usage, use, launch, develop, or
                      distribute any automated system, including without
                      limitation, any spider, robot, cheat utility, scraper, or
                      offline reader that accesses the Site, or using or
                      launching any unauthorized script or other software.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Use a buying agent or purchasing agent to make purchases
                      on the Site.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Make any unauthorized use of the Site, including
                      collecting usernames and/or email addresses of users by
                      electronic or other means for the purpose of sending
                      unsolicited email, or creating user accounts by automated
                      means or under false pretenses.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Use the Site as part of any effort to compete with us or
                      otherwise use the Site and/or the Content for any
                      revenue-generating endeavor or commercial enterprise.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Use the Site to advertise or offer to sell goods and
                      services.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">Sell or otherwise transfer your profile.</li>
                  </ul>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="USER GENERATED CONTRIBUTIONS" key="tab8">
              <div>
              <h5 className="pb-4">USER GENERATED CONTRIBUTIONS</h5>
                  <p className="wow animate__animated animate__slideInRight">
                    The Site does not offer users to submit or post content. We
                    may provide you with the opportunity to create, submit,
                    post, display, transmit, perform, publish, distribute, or
                    broadcast content and materials to us or on the Site,
                    including but not limited to text, writings, video, audio,
                    photographs, graphics, comments, suggestions, or personal
                    information or other material (collectively,
                    "Contributions"). Contributions may be viewable by other
                    users of the Site and through third-party websites. As such,
                    any Contributions you transmit may be treated in accordance
                    with the Site Privacy Policy. When you create or make
                    available any Contributions, you thereby represent and
                    warrant that:
                  </p>
                  <ul>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      The creation, distribution, transmission, public display,
                      or performance, and the accessing, downloading, or copying
                      of your Contributions do not and will not infringe the
                      proprietary rights, including but not limited to the
                      copyright, patent, trademark, trade secret, or moral
                      rights of any third party.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      You are the creator and owner of or have the necessary
                      licenses, rights, consents, releases, and permissions to
                      use and to authorize us, the Site, and other users of the
                      Site to use your Contributions in any manner contemplated
                      by the Site and these Terms of Use.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      You have the written consent, release, and/or permission
                      of each and every identifiable individual person in your
                      Contributions to use the name or likeness of each and
                      every such identifiable individual person to enable
                      inclusion and use of your Contributions in any manner
                      contemplated by the Site and these Terms of Use.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Your Contributions are not false, inaccurate, or
                      misleading.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Your Contributions are not unsolicited or unauthorized
                      advertising, promotional materials, pyramid schemes, chain
                      letters, spam, mass mailings, or other forms of
                      solicitation.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Your Contributions are not obscene, lewd, lascivious,
                      filthy, violent, harassing, libelous, slanderous, or
                      otherwise objectionable (as determined by us).
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Your Contributions do not ridicule, mock, disparage,
                      intimidate, or abuse anyone.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Your Contributions are not used to harass or threaten (in
                      the legal sense of those terms) any other person and to
                      promote violence against a specific person or class of
                      people.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Your Contributions do not violate any applicable law,
                      regulation, or rule.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Your Contributions do not violate the privacy or publicity
                      rights of any third party.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Your Contributions do not violate any applicable law
                      concerning child pornography, or otherwise intended to
                      protect the health or well-being of minors.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Your Contributions do not include any offensive comments
                      that are connected to race, national origin, gender,
                      sexual preference, or physical handicap.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      Your Contributions do not otherwise violate, or link to
                      material that violates, any provision of these Terms of
                      Use, or any applicable law or regulation.
                    </li>
                  </ul>
                  <p className="pt-4 wow animate__animated animate__slideInRight">
                    Any use of the Site in violation of the foregoing violates
                    these Terms of Use and may result in, among other things,
                    termination or suspension of your rights to use the Site.
                  </p>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="CONTRIBUTION LICENSE" key="tab9">
              <div>
              <h5 className="pb-4">CONTRIBUTION LICENSE</h5>
                  <p className="wow animate__animated animate__slideInRight">
                    You and the Site agree that we may access, store, process,
                    and use any information and personal data that you provide
                    following the terms of the Privacy Policy and your choices
                    (including settings).
                  </p>
                  <p className="wow animate__animated animate__slideInRight">
                    By submitting suggestions or other feedback regarding the
                    Site, you agree that we can use and share such feedback for
                    any purpose without compensation to you.
                  </p>
                  <p className="wow animate__animated animate__slideInRight">
                    We do not assert any ownership over your Contributions. You
                    retain full ownership of all of your Contributions and any
                    intellectual property rights or other proprietary rights
                    associated with your Contributions. We are not liable for
                    any statements or representations in your Contributions
                    provided by you in any area on the Site. You are solely
                    responsible for your Contributions to the Site and you
                    expressly agree to exonerate us from any and all
                    responsibility and to refrain from any legal action against
                    us regarding your Contributions.
                  </p>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="GUIDELINES FOR REVIEWS" key="tab10">
              <div>
              <h5 className="pb-4">GUIDELINES FOR REVIEWS</h5>
                  <p className="wow animate__animated animate__slideInRight">
                    We may provide you areas on the Site to leave reviews or
                    ratings. When posting a review, you must comply with the
                    following criteria:
                  </p>
                  <ul>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      you should have firsthand experience with the
                      person/entity being reviewed.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      your reviews should not contain offensive profanity, or
                      abusive, racist, offensive, or hate language.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      your reviews should not contain discriminatory references
                      based on religion, race, gender, national origin, age,
                      marital status, sexual orientation, or disability.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      your reviews should not contain references to illegal
                      activity.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      you should not be affiliated with competitors if posting
                      negative reviews.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      you should not make any conclusions as to the legality of
                      conduct.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      you may not post any false or misleading statements.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      you may not organize a campaign encouraging others to post
                      reviews, whether positive or negative.
                    </li>
                  </ul>

                  <p className="pt-4 wow animate__animated animate__slideInRight">
                    We may accept, reject, or remove reviews in our sole
                    discretion. We have absolutely no obligation to screen
                    reviews or to delete reviews, even if anyone considers
                    reviews objectionable or inaccurate. Reviews are not
                    endorsed by us, and do not necessarily represent our
                    opinions or the views of any of our affiliates or partners.
                    We do not assume liability for any review or for any claims,
                    liabilities, or losses resulting from any review. By posting
                    a review, you hereby grant to us a perpetual, non-exclusive,
                    worldwide, royalty-free, fully-paid, assignable, and
                    sublicensable right and license to reproduce, modify,
                    translate, transmit by any means, display, perform, and/or
                    distribute all content relating to reviews.
                  </p>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="SUBMISSIONS" key="tab11">
              <div>
              <h5 className="pb-4">SUBMISSIONS</h5>
                  <p className="wow animate__animated animate__slideInRight">
                    You acknowledge and agree that any questions, comments,
                    suggestions, ideas, feedback, or other information regarding
                    the Site ("Submissions") provided by you to us are
                    non-confidential and shall become our sole property. We
                    shall own exclusive rights, including all intellectual
                    property rights, and shall be entitled to the unrestricted
                    use and dissemination of these Submissions for any lawful
                    purpose, commercial or otherwise, without acknowledgment or
                    compensation to you. You hereby waive all moral rights to
                    any such Submissions, and you hereby warrant that any such
                    Submissions are original with you or that you have the right
                    to submit such Submissions. You agree there shall be no
                    recourse against us for any alleged or actual infringement
                    or misappropriation of any proprietary right in your
                    Submissions.
                  </p>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="THIRD-PARTY WEBSITES AND CONTENT" key="tab12">
              <div>
              <h5 className="pb-4">THIRD-PARTY WEBSITES AND CONTENT</h5>
                  <p className="wow animate__animated animate__slideInRight">
                    The Site may contain (or you may be sent via the Site) links
                    to other websites ("Third-Party Websites") as well as
                    articles, photographs, text, graphics, pictures, designs,
                    music, sound, video, information, applications, software,
                    and other content or items belonging to or originating from
                    third parties ("Third-Party Content"). Such Third-Party
                    Websites and Third-Party Content are not investigated,
                    monitored, or checked for accuracy, appropriateness, or
                    completeness by us, and we are not responsible for any
                    Third-Party Websites accessed through the Site or any
                    Third-Party Content posted on, available through, or
                    installed from the Site, including the content, accuracy,
                    offensiveness, opinions, reliability, privacy practices, or
                    other policies of or contained in the Third-Party Websites
                    or the Third-Party Content. Inclusion of, linking to, or
                    permitting the use or installation of any Third-Party
                    Websites or any Third-Party Content does not imply approval
                    or endorsement thereof by us. If you decide to leave the
                    Site and access the Third-Party Websites or to use or
                    install any Third-Party Content, you do so at your own risk,
                    and you should be aware these Terms of Use no longer govern.
                    You should review the applicable terms and policies,
                    including privacy and data gathering practices, of any
                    website to which you navigate from the Site or relating to
                    any applications you use or install from the Site. Any
                    purchases you make through Third-Party Websites will be
                    through other websites and from other companies, and we take
                    no responsibility whatsoever in relation to such purchases
                    which are exclusively between you and the applicable third
                    party. You agree and acknowledge that we do not endorse the
                    products or services offered on Third-Party Websites and you
                    shall hold us harmless from any harm caused by your purchase
                    of such products or services. Additionally, you shall hold
                    us harmless from any losses sustained by you or harm caused
                    to you relating to or resulting in any way from any
                    Third-Party Content or any contact with Third-Party
                    Websites.
                  </p>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="SITE MANAGEMENT" key="tab13">
              <div>
              <h5 className="pb-4">SITE MANAGEMENT</h5>
                  <p className="wow animate__animated animate__slideInRight">
                    <strong>
                      We reserve the right, but not the obligation, to:
                    </strong>
                  </p>
                  <ul>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      monitor the Site for violations of these Terms of Use.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      take appropriate legal action against anyone who, in our
                      sole discretion, violates the law or these Terms of Use,
                      including without limitation, reporting such user to law
                      enforcement authorities.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      in our sole discretion and without limitation, refuse,
                      restrict access to, limit the availability of, or disable
                      (to the extent technologically feasible) any of your
                      Contributions or any portion thereof.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      in our sole discretion and without limitation, notice, or
                      liability, to remove from the Site or otherwise disable
                      all files and content that are excessive in size or are in
                      any way burdensome to our systems.
                    </li>
                    <li className="wow fadeInUp" data-wow-duration="1s">
                      otherwise manage the Site in a manner designed to protect
                      our rights and property and to facilitate the proper
                      functioning of the Site.
                    </li>
                  </ul>
              </div>
            </Tabs.TabPane>
       
          </Tabs>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default TermsAndConditions;
