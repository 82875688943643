import React from "react";
import Appointment from "../Appointment";
import { Link } from "react-router-dom";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import ServisesSlider from "./ServisesSlider";
import "swiper/css/pagination";


const Clipinonhairextensionsformen = () => {
  return (
    <>
      <Header />
      <section className="about-banner">
        <div className="container">
          <div className="banner-content">
            <h2>Tape In & Clip in on Hair Extensions for Men</h2>
          </div>
        </div>
      </section>

      <section className="contact-section pb-0">
        <div className="container">
          <div className="section-header text-center mb-4">
          <h2 className="text-title text-uppercase ">
          Hair Extensions for Men
            </h2>
            <p className="text-hr  mt-0"></p>
          </div>
          </div>
      </section>
      <section id="services" className="services pt-4">
				<div className="container">
					<div className="row">
						<div className="col-md-6 col-12">
							<div className="img-holder">
							<Link to="/"><img src="images/benefits/hair-extensions-for-men.png" alt="logo" className="img-fluid" /></Link>
							</div>
						</div>
						<div className="col-md-6 col-12">
							<div className="d-flex align-items-center h-100">
								<div className="text-left">
									<div className="process-text">
							<p className="mb-2 text-subtitle-services">
              Are you thinking of adding more volume and thickness to your
              natural hair? But cannot do that natural due to thinning of hair
              or might be causing hair loss because of an ageing issue or any
              medical condition. Despite that, you have the best option to add
              more thickness and fulfilling volume to your head hair. It can be
              achieved by availing hair extensions for men service here at
              Radiance Hair Studio.</p>
							<p className="mb-2 text-subtitle-services">
              Extensions are several layers of different lengths, styles,
              volumes, and thickness of hair parts for your head. Get such
              layers added to your existing hair and bring in more volume to
              achieve the desired physical outlook. Thus, to help you with
              pain-free and non-surgical hair extensions added to your head, we
              use two methods i.e. Tape In and Clip In to fix extensions
              perfectly.</p>
							<p className="mb-2 text-subtitle-services">
              Both the techniques offer an instant, flawless, and pain-free way
              of adding more hair to your natural ones and finishing up the look
              completely.</p>
									</div>
									<button className="btn btn-theme mt-4">Learn More</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

<ServisesSlider/>
      <section id="services" className="services pt-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="d-flex align-items-center h-100">
                <div className="text-left">
                  <h2 className="title text-title-services text-uppercase border-left text-left">
                    Tape In on Hair Extensions for Men
                  </h2>
                  <div className="process-text">
                    <p className="mb-2 about-text">
                      Tape In hair extension for men in Delhi is the most
                      affordable and easy experience a way of adding several
                      layers of hair. It is a semi-permanent way of adding hair
                      that usually has a time limit of 6-8 weeks, before
                      requiring necessary adjustments and re-fitting.
                    </p>
                    <p className="mb-2 about-text">
                      An adhesive tape with glue is attached to the extension
                      that perfectly sticks to your existing hair or even to a
                      bald scalp. It fixes like a close root to the head and
                      provides a natural and thin finish to the eyes of
                      onlookers. From the view itself, men’s tape in hair
                      extensions appears completely natural and realistic. In
                      this way, you can bring natural styling to your hairstyle
                      by taping into the preferred choice of hair extensions.
                    </p>
                  </div>
                  {/* <button className="btn btn-theme mt-4">Learn More</button> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="img-holder">
                <Link to="/">
                  <img
                    src="images/hair-extensions-for-men-1.png"
                    alt="Our Mission"
                    className="img-fluid"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="services" className="services pb-3">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="img-holder">
                <Link to="/">
                  <img
                    src="images/hair-extensions-for-men-2.png"
                    alt="Our Mission"
                    className="img-fluid"
                  />
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="d-flex align-items-center h-100">
                <div className="text-left">
                  <h2 className="title text-title-services text-uppercase border-left text-left">
                    Clip In on Hair Extensions for Men
                  </h2>
                  <div className="process-text">
                    <p className="mb-2 about-text">
                      Clip In on hair extensions is another way to naturally fix
                      the desired choice of additional hair layers. As the name
                      itself depicts, several clips are attached to every single
                      extension layer that can be swiftly fixed in with the
                      natural hair and you are ready with the handsome outlook.
                    </p>
                    <p className="mb-2 about-text">
                      This is the least permanent choice of the way to add
                      extensions on your head and brings an instant change in
                      your personality. The best part is the do it yourself
                      approach allowing any man to simply clip on or off the
                      layers of hair extensions as per particular choice. Also,
                      it is easy to remove and comprises 100% natural hair to
                      complete your favourable personality.
                    </p>
                  </div>
                  {/* <button className="btn btn-theme mt-4">Learn More</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
   
      <Appointment/>
      <Footer />
    </>
  );
};

export default Clipinonhairextensionsformen;
