import React from "react";
import { Link } from "react-router-dom";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import ServisesSlider from "./ServisesSlider";
import Appointment from "../Appointment";
import "swiper/css/pagination";

const Nonsurgicalhairreplacementformen = () => {
  return (
    <>
      <Header />
      <section className="about-banner">
        <div className="container">
          <div className="banner-content">
            <h2>Non-Surgical Hair Replacement for Men</h2>
          </div>
        </div>
      </section>
      <section className="contact-section pb-0">
        <div className="container">
          <div className="section-header text-center mb-4">
          <h2 className="text-title text-uppercase ">
          Non-Surgical Hair Replacement for Men
            </h2>
            <p className="text-hr  mt-0"></p>
          </div>
          </div>
      </section>

      <section id="services" className="services pt-4">
				<div className="container">
					<div className="row">
						<div className="col-md-6 col-12">
							<div className="img-holder">
							<Link to="/"><img src="images/benefits/non-surgical-hair-replacement-for-men.png" alt="logo" className="img-fluid" /></Link>
							</div>
						</div>
						<div className="col-md-6 col-12">
							<div className="d-flex align-items-center h-100">
								<div className="text-left">
									<div className="process-text">
							<p className="mb-2 text-subtitle-services">
              Are you slowly losing your hair on the scalp and resulting in a
              bald outlook? If yes, then your sense of confidence must be on the
              lower side. For men, hair fall is a common problem occurred due to
              genetics issues, male pattern baldness, diet, pollution,
              lifestyle, and more. The ideal hair growing solution is an apt
              alternative to wear that desired outlook and feel confident among
              others. Thus, to help you attain the outlook of naturally flowing
              hair, we offer you affordable and pain-free non- surgical hair
              replacement for men.
              </p>
              <p className="mb-2 text-subtitle-services">
              We at Radiance Hair Studio have come to your air by offering
              end-to-end hair growing services as per your requirements. Deal
              with the constant hair fall issue and avail non-surgical hair
              replacement for men in Delhi to once again cover up your head with
              100% human hair. Non- surgical hair replacement is a technically
              innovative and cost-effective hair growing treatment for men.
              </p>
              <p className="text-subtitle-services">
              Our expert team of hairstylists gives you a detailed look at this
              hair growing solution by attaching a membrane to your scalp
              infused with human hair. One can swiftly wear the membrane like a
              wig on the scalp that appears natural to the eyes of onlookers. It
              creates a more subtle, comfortable, and far more natural
              appearance to easily cover the baldness and create a fascinating
              appearance with flowing hairs on the scalp.
              </p>

									</div>
									<button className="btn btn-theme mt-4">Learn More</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

      <ServisesSlider />
      <section id="services" className="services pt-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="d-flex align-items-center h-100">
                <div className="text-left">
                  <h2 className="title text-title-services text-uppercase text-left">
                    Best Price Non-Surgical Hair Replacement Therapy
                  </h2>
                  <div className="process-text">
                    <p className="mb-2 about-text">
                      For men facing the problematic issue of hair loss and
                      requires a pain-free solution to grow hairs, then
                      non-surgical hair replacement treatment is the one to go
                      for. As the name implies, no surgery is involved in the
                      process of attaching the membrane full of human hair to
                      your scalp. It offers the instant solution to fill up the
                      head with naturally flowing hair and provides you with a
                      convincing and realistic outlook.
                    </p>
                    <p className="mb-2 about-text">
                      The non-surgical hair growing solution offered by us is a
                      non-medical and non-invasive solution to fill up your head
                      with hair with no side effects at all. Our real-time hair
                      experts offer you the initial stage of design consultation
                      of the membrane followed by the preferred colour of the
                      patch, the right size, density of hair, and fixing the
                      hairline at the end of the process. It is the most
                      successful hair growing solution for men to wear the
                      desired look in front of other people around and showcase
                      a level of confidence and fashionable appearance.
                    </p>
                  </div>
                  {/* <button className="btn btn-theme mt-4">Learn More</button> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="img-holder">
                <Link to="/">
                  <img
                    src="images/non-surgical-hair-replacement-for-men.png"
                    alt="Our Mission"
                    className="img-fluid"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
     

<Appointment/>
      <Footer />
    </>
  );
};

export default Nonsurgicalhairreplacementformen;
