import React from "react";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import AboutSection from "./AboutSection";
import Vision from "./Vision";
import Mission from "./Mission";
import Appointment from "../Appointment";




const About: React.FC = () => {

  return (
    <>
<Header />
<AboutSection/>
<Vision/>
<Mission/>
<Appointment/>
<Footer />

    </>
  );
};

export default About;
