import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const flagSlider = [
  {
    customerName: "Vijay Solanki",
    designation:"Hair Transplant",
    customerTitle:
      "Bahut badiya doctor hai ,Muujhe 2 saal se pimple Ho rage the . Indore m Maine 4 doctors ko bhi dikhya. At the last he explained m the disease and give m 8 months course. I m pimple free now thanks a lot sir. Best is he is affordable.",
  },
  {
    customerName: "Pranav Shrivastava",
    designation:"Hair Transplant",
    customerTitle:
      "I consulted Dr Pancholi and found his treatment very effective. I appreciate	his kind and down to earth personality. I truly recommend people for their derma care to Dr Pancholi.",
  },
  {
    customerName: "Nilay Jain",
    designation:"Hair Transplant",
    customerTitle:
      "The best Hair transplant surgeon in Madhya Pradesh, Such a great experience. Took all the time to go over my questions. I left the clinic already feeling much better. Thank you!” the best Hair transplant surgeon in MP. ",
  },
  {
    customerName: "Dushyant Dwivedi",
    designation:"Hair Transplant",
    customerTitle:
      "A place which offers complete solution for derma care, skin care, hair related problems and all cosmetology related problems. Dr. Sanjay Pancholi is a renowned dermatologist of very helpful caring and friendly nature.",
  },
  {
    customerName: "Sandal Tomar",
    designation:"Hair Transplant",
    customerTitle:
      "Wonderful! Excellent knowledge and expertise - Dermatologist: Dr. Sanjay Pancholi I’m so pleased with my experience here. Before the procedure I was worried about the outcome, later I was thankful for the result. Thank you for your time and effort! :-))",
  },
];

const CustomerTestimonial = () => {
  return (
    <>
    <section className="section-lg testimonial-one-bg pt-0">
    <div className="container">
      <div className="pbmit-heading-subheading text-center">
        <h4 className="pbmit-subtitle">OUR CLIENTS</h4>
        <h2 className="pbmit-title">What our Clients say</h2>
        <div className="pbmit-heading-content">
          <p>Experience hair that shines from within at Radiance Hair Studio</p>
        </div>
      </div>
        <br></br>
        <div className="col-md-12 col-12 ">
          <div className="container">
            <Swiper
             centeredSlides={true}
			//   className="slider"
              spaceBetween={10}
              slidesPerView={2}
                 
			  pagination={{
				clickable: true,
			  }}
			  loop={true}
              autoplay={true}
              navigation={{
                prevEl: ".course-navi-prev",
                nextEl: ".course-navi-next",
              }}

              modules={[Autoplay, Navigation ,Pagination]}
              breakpoints={{
                0: {
                  width: 0,
                  slidesPerView: 1,
                },
                680: {
                  width: 680,
                  slidesPerView: 3,
                },
                1200: {
                  width: 1200,
                  slidesPerView: 3,
                },
              }}
            >
              {flagSlider.map((val, i) => (
                <SwiperSlide key={i}>
                  <article className="pbmit-testimonial-style-3">
									<div className="pbminfotech-post-item">
										<div className="pbminfotech-box-content">
											<div className="pbminfotech-box-desc">
												<blockquote className="pbminfotech-testimonial-text">
													<p> {val.customerTitle}</p>
												</blockquote>
											</div>
										</div>
										<div className="pbminfotech-box-author">
											<h3 className="pbminfotech-box-title"> {val.customerName}</h3>
											<span className="pbmit-designation">{val.designation}</span> 
										</div>
									</div>
								</article>
                </SwiperSlide>
              ))}
            </Swiper>
          
      </div>
      </div>
      </div>
    </section>
    </>
  );
};

export default CustomerTestimonial;
