import React from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { Link } from "react-router-dom";
import { Tabs } from "antd";

const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <Header />
      <section className="about-banner">
        <div className="container">
          <div className="banner-content">
            <h2 className="wow fadeInLeft" data-wow-duration="1s">
            Privacy Policy
            </h2>
          </div>
          <div className="sc_layouts_title_breadcrumbs wow fadeInLeft">
            <div className="breadcrumbs">
              <a className="breadcrumbs_item home" href="/radiance/">
                Home
              </a>
              <span className="breadcrumbs_delimiter"></span>
              <span className="breadcrumbs_item current">
              Privacy Policy
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="privacy-section pb-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-xl-12">
              <div className="w-full privacy">
                <div className="content-area">
                  <header className="entry-header">
                    <div className="d-flex align-items-center text-white">
                      <div>Privacy Policy</div>
                      <div className="ml-auto align-items-center">
                        <div className="dl">Last updated : 28 August 2023</div>
                      </div>
                    </div>
                  </header>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="hair-replacement" className="term-condition pt-5">
        <div className="container">
          <Tabs defaultActiveKey="tab1" tabPosition="left">
            <Tabs.TabPane tab="PRIVACY POLICY" key="tab1">
              <div>
                <h5 className="pb-4">PRIVACY POLICY</h5>
                <p className="wow animate__animated animate__slideInRight" >
                We care about data privacy and security. By using the Site, you agree to be bound by our Privacy Policy posted on the Site, which is incorporated into these Terms of Use. Please be advised the Site is hosted in India . If you access the Site from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in India , then through your continued use of the Site, you are transferring your data to India , and you agree to have your data transferred to and processed in India .
                </p>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="COPYRIGHT INFRINGEMENTS" key="tab2">
              <div>
              <h5 className="pb-4">COPYRIGHT INFRINGEMENTS</h5>
                  <p className="wow animate__animated animate__slideInRight" >
                  We respect the intellectual property rights of others. If you believe that any material available on or through the Site infringes upon any copyright you own or control, please immediately notify us using the contact information provided below (a "Notification"). A copy of your Notification will be sent to the person who posted or stored the material addressed in the Notification. Please be advised that pursuant to applicable law you may be held liable for damages if you make material misrepresentations in a Notification. Thus, if you are not sure that material located on or linked to by the Site infringes your copyright, you should consider first contacting an attorney.
                  </p>

              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="TERM AND TERMINATION" key="tab3">
              <div>
              <h5 className="pb-4">TERM AND TERMINATION</h5>
                  <p className="wow animate__animated animate__slideInRight"  >
                  These Terms of Use shall remain in full force and effect while you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
                  </p>
                <p className="wow animate__animated animate__slideInRight"  >If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.</p>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="MODIFICATIONS AND INTERRUPTIONS" key="tab4">
              <div>
              <h5 className="pb-4">MODIFICATIONS AND INTERRUPTIONS</h5>
                  <p className="wow animate__animated animate__slideInRight"  >
                  We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Site. We also reserve the right to modify or discontinue all or part of the Site without notice at any time. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Site.
                  </p>
              <p className="wow animate__animated animate__slideInRight"  >We cannot guarantee the Site will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Site, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Site at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Site during any downtime or discontinuance of the Site. Nothing in these Terms of Use will be construed to obligate us to maintain and support the Site or to supply any corrections, updates, or releases in connection therewith.</p>

              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="GOVERNING LAW" key="tab5">
              <div>
              <h5 className="pb-4">GOVERNING LAW</h5>
                  <p className="wow animate__animated animate__slideInRight"  >
                  These Terms shall be governed by and defined following the laws of India. Radiance Hair Studio and yourself irrevocably consent that the courts of India shall have exclusive jurisdiction to resolve any dispute which may arise in connection with these terms.
                  </p>
              
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="DISPUTE RESOLUTION" key="tab6">
              <div>
              <h5 className="pb-4">DISPUTE RESOLUTION</h5>

                  <p className="wow animate__animated animate__slideInRight"  >Any disputes would be subject to Delhi jurisdiction.</p>
                 <h6 className="wow animate__animated animate__slideInRight"  >Informal Negotiations</h6>
                 <p className="wow animate__animated animate__slideInRight"  >To expedite resolution and control the cost of any dispute, controversy, or claim related to these Terms of Use (each "Dispute" and collectively, the "Disputes") brought by either you or us (individually, a "Party" and collectively, the "Parties"), the Parties agree to first attempt to negotiate any Dispute (except those Disputes expressly provided below) informally for at least thirty (30) days before initiating arbitration. Such informal negotiations commence upon written notice from one Party to the other Party.</p>
                 <h6 className="wow animate__animated animate__slideInRight"  >Binding Arbitration</h6>
                 <p className="wow animate__animated animate__slideInRight"  >Any dispute arising out of or in connection with this contract, including any question regarding its existence, validity, or termination, shall be referred to and finally resolved by the Indian Law in New Delhi, The number of arbitrators shall be three (3). The seat, or legal place, of arbitration shall be New Delhi, India. The language to be used in the arbitral proceedings shall be English. The governing law of the contract shall be the substantive law of India.</p>
                 <h6 className="wow animate__animated animate__slideInRight"  >Restrictions</h6>
                 <p className="wow animate__animated animate__slideInRight"  >The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. To the full extent permitted by law, (a) no arbitration shall be joined with any other proceeding; (b) there is no right or authority for any Dispute to be arbitrated on a class-action basis or to utilize class action procedures; and (c) there is no right or authority for any Dispute to be brought in a purported representative capacity on behalf of the general public or any other persons.</p>
                 <h6 className="wow animate__animated animate__slideInRight"  >Exceptions to Informal Negotiations and Arbitration</h6>
                 <p className="wow animate__animated animate__slideInRight"  >The Parties agree that the following Disputes are not subject to the above provisions concerning informal negotiations and binding arbitration: (a) any Disputes seeking to enforce or protect, or concerning the validity of, any of the intellectual property rights of a Party; (b) any Dispute related to, or arising from, allegations of theft, piracy, invasion of privacy, or unauthorized use; and (c) any claim for injunctive relief. If this provision is found to be illegal or unenforceable, then neither Party will elect to arbitrate any Dispute falling within that portion of this provision found to be illegal or unenforceable and such Dispute shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that court.</p>
                </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="CORRECTIONS" key="tab7">
              <div>
              <h5 className="pb-4">CORRECTIONS</h5>

                  <p className="wow animate__animated animate__slideInRight"  >
                  There may be information on the Site that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Site at any time, without prior notice.
                  </p>

                 
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="DISCLAIMERS" key="tab8">
              <div>
              <h5 className="pb-4">DISCLAIMER</h5>
                  <p className="wow animate__animated animate__slideInRight"  >
                  THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITEâ€™S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
                  </p>
              
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="LIMITATIONS OF LIABILITY" key="tab9">
              <div>
              <h5 className="pb-4">LIMITATIONS OF LIABILITY</h5>
                
                  <p className="wow animate__animated animate__slideInRight"  >
                  IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE ONE (1) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING . CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
                  </p>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="INDEMNIFICATION" key="tab10">
              <div>
              <h5 className="pb-4">INDEMNIFICATION</h5>
                  <p className="wow animate__animated animate__slideInRight"  >
                  You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneysâ€™ fees and expenses, made by any third party due to or arising out of: (1) use of the Site; (2) breach of these Terms of Use; (3) any breach of your representations and warranties set forth in these Terms of Use; (4) your violation of the rights of a third party, including but not limited to intellectual property rights; or (5) any overt harmful act toward any other user of the Site with whom you connected via the Site. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.
            </p>
             
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="USER DATA" key="tab11">
              <div>
              <h5 className="pb-4">USER DATA</h5>
                  <p className="wow animate__animated animate__slideInRight"  >
                  We will maintain certain data that you transmit to the Site for the purpose of managing the performance of the Site, as well as data relating to your use of the Site. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Site. You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.
                  </p>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="ELECTRONIC COMMUNICATIONS" key="tab12">
              <div>
              <h5 className="pb-4">ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h5>
                  <p className="wow animate__animated animate__slideInRight"  >
                  Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.
                  </p>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="MISCELLANEOUS" key="tab13">
              <div>
              <h5 className="pb-4">MISCELLANEOUS</h5>
                  <p className="wow animate__animated animate__slideInRight"  >
                  These Terms of Use and any policies or operating rules posted by us on the Site or in respect to the Site constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Terms of Use shall not operate as a waiver of such right or provision. These Terms of Use operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of these Terms of Use is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Terms of Use and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Terms of Use or use of the Site. You agree that these Terms of Use will not be construed against us by virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of these Terms of Use and the lack of signing by the parties hereto to execute these Terms of Use.
                  </p>
                
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="CONTACT US" key="tab14">
              <div>
              <h5 className="pb-4">CONTACT US</h5>
                  <p className="wow animate__animated animate__slideInRight"  >
                 In order to resolve a complaint regarding the Site or to receive further information regarding use of the Site, please contact us at:
                  </p>
                <p className="wow animate__animated animate__slideInRight"  >
                    <strong>Radiance Hair Studio</strong>
                    <br/>
                    <b>A-66, 3rd Floor, Beside Metro Station Gate No 5, Block A, Rajouri Garden</b><br/>
                    <b>New Delhi , Delhi 110027</b><br/>
                    <b>India</b><br/>
                    <b><Link to="tel:+91-99585-85649" style={{color: '#555'}}>Phone: +91-99585-85649 </Link></b><br/>
                    <b><Link to="mailto:contact@radiancehairstudio.com" style={{color: '#555'}}>contact@radiancehairstudio.com</Link> </b>
                </p>
              </div>
            </Tabs.TabPane>
          </Tabs>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
