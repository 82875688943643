
import { Link } from "react-router-dom";

const quickLink = "Quick Links";

const quickList = [
    {
        text: 'About',
        link: '/about',
    },
    // {
    //     text: 'Services',
    //     link: 'services',
    // },
    // {
    //     text: 'Resorces',
    //     link: '#',
    // },
    {
        text: 'Contact Us',
        link: '/contact-us',
    },
    
]

const tweetList = [
    {
        iconName: 'icofont-twitter',
        desc: <p>Aminur islam <Link to="#">@CodexCoder Edukon #HTML_Template</Link> Grab your item, 50% Big Sale Offer !!</p>,
    },

]

const Footer = () => {
    return (
        <footer className="style-2">
            <div className="footer-top dark-view padding-tb pb-2">
                <div className="container">
                    <div className="row g-4 row-cols-xl-4 row-cols-sm-2 row-cols-1 justify-content-center">
                        <div className="col">
                            <div className="footer-item our-address">
                                <div className="footer-inner">
                                    <div className="footer-content">
                                        <div className="content">
                         
                                       
							<div className="hb-col">
						
                            <div className="logo-search-acte">
                    <div className="logo">
                        {/* <Link to="/"><img src="assets/images/logo/logo.png" alt="logo" /></Link> */}
                    </div>
                </div>
								<p className="mb-4">Open hours: 8.00-18.00 Mon-Fri</p>
								<h4>FOLLOW US</h4>
								<ul className="lab-ul social-icons">
									<li><Link to="https://www.facebook.com/radiancehairstudio1"><i className="fa fa-facebook"></i></Link></li>
									<li><Link to="https://twitter.com/i/flow/login?redirect_after_login=%2Fstudio_radiance"><i className="fa fa-twitter"></i></Link></li>
									<li><Link to="https://www.youtube.com/channel/UCLWRJlt4j04qSGUjvBThbig"><i className="fa fa-youtube"></i></Link></li>
									<li><Link to="/https://www.instagram.com/radiancehairofficial/"><i className="fa fa-instagram"></i></Link></li>
								</ul>
							</div>
						</div>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="footer-item">
                                <div className="footer-inner">
                                    <div className="footer-content">
                                        <div className="title">
                                            <h4>{quickLink}</h4>
                                        </div>
                                        <div className="content">
                                            <ul className="lab-ul">
                                            <li><Link to="/about-us">About</Link></li>   
                                            <li><Link to="/contact-us">Contact Us</Link></li> 
                                            <li><Link to="/">Services</Link></li> 
                                            <li><Link to="/">Resources</Link></li> 
                                          
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col">
                            <div className="footer-item">
                                <div className="footer-inner">
                                    <div className="footer-content">
                                        <div className="title">
                                            <h4>Services</h4>
                                        </div>
                                        <div className="content">
                                            <ul className="lab-ul">
                                              
                                                    <li><Link to="">Customized Wigs for Men</Link></li>
                                                    <li><Link to="">Customized Wigs for Women</Link></li>
                                                    <li><Link to="">Tape In & Clip In on Hair Extensions for Men</Link></li>
                                                    <li><Link to="">Tape In & Clip In on Hair Extensions for Women</Link></li>
                                                    <li><Link to="">Hair Bonding for Men</Link></li>
                                                    <li><Link to="">Hair Fixing for Men</Link></li>
                                                    <li><Link to="">Human Hair Extension</Link></li>
                                                    <li><Link to="">Non-Surgical Hair Replacement for Men</Link></li>
                                                    <li><Link to="">Non-Surgical Hair Replacement for Women</Link></li>
                                                    <li><Link to="">Volumiser Hair Additions</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="col">
                            <div className="footer-item">
                                <div className="footer-inner">
                                    <div className="footer-content">
                                        <div className="title">
                                            <h4>Contacts</h4>
                                        </div>
                                        <div className="content">
                           
                           <ul className="lab-ul office-address">
                              
                                   <li>099585 85649</li>
                                   <li>contact@radiancehairstudio.com</li>
                                   <li>3rd Floor, A-2, Rajouri Garden, <br></br> New Delhi, Delhi 110027</li>
                        
                           </ul>
                       </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                
                         <div className="col">
                            <div className="footer-item twitter-post">
                                <div className="footer-inner">
                                    <div className="footer-content">
                                        <div className="title">
                                            <h4>Our NewsLetter</h4>
                                        </div>
                                       
                                        <div className="hb-emailarea">
									<form className="hb-formtheme">
										<fieldset>
											<div className="form-group poss-rel">
												<input type="text" className="form-control" placeholder="E-mail" />
												<button type="submit" className="hb-btn">SUBSCRIBE</button>
											</div>
										</fieldset>
									</form>
								</div>
                                	{/* <div className="hb-description mt-3">
									<p>Subscribe to our mailing list to get the updates to your email inbox.</p>
								</div> */}
                                        </div>
                                    </div>
                                
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
			<div className="hb-footerbar">
				<div className="container">
					<div className="row">
						<div className="col-xs-12 col-sm-12 col-md-12">
							<span className="hb-copyright">Copyright ©2022 Radiancehairstudio.</span>
							<ul className="list-unstyled hb-footernav">
								<li><Link to="/terms-and-conditions">Terms & Conditions</Link></li>
								<li><Link to="/privacy-policy">Privacy Policy</Link></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
        </footer>
    );
}
 
export default Footer;