import { useState } from "react";
import { NavLink } from 'react-router-dom';
import {Link} from 'react-scroll'


const Header = () => {
    const [menuToggle, setMenuToggle] = useState(false);
	const [socialToggle, setSocialToggle] = useState(false);
	const [headerFiexd, setHeaderFiexd] = useState(false);

    window.addEventListener("scroll", () => {
		if (window.scrollY > 10) {
			setHeaderFiexd(true);
		} else {
			setHeaderFiexd(false);
		}
	});
    
    return (
        
<header  className={`header-section style-3 style-5 ${headerFiexd ? "header-fixed fadeInUp" : ""}`}>
    <div className="header-top d-md-none">
        <div className="container">
            <div className="header-top-area">
                <Link to="/signup" className="lab-btn me-3"><span>Create Account</span></Link>
                <Link to="/login">Log In</Link>
            </div>
        </div>
    </div>
    <div className="header-bottom">
        <div className="contai  ner">
            <div className="header-wrapper">
                <div className="logo-search-acte">
                    <div className="logo">
                        <NavLink to="/"><img src="images/logo/logo.png" alt="Radiance" /></NavLink>
                    </div>
                </div>
                <div className="menu-area">
                    <div className="menu">
                    <ul className="lab-ul">  
                    <li><NavLink to="/">Home</NavLink></li>                    
                     <li><NavLink to="/about-us">About</NavLink></li>           
                     <li className="has-children">
                    <Link to="#Services" className="nav-link">Services</Link>
                    <ul className="dropdown arrow-top" >
                      <li><NavLink to="/customized-wigs-for-men" className="nav-link">Customized Wigs for Men</NavLink></li>
                      <li><NavLink to="/customized-wigs-for-women" className="nav-link">Customized Wigs for Women</NavLink></li>
                      <li><NavLink to="/hair-extensions-for-men" className="nav-link">Tape In & Clip In on Hair Extensions for Men</NavLink></li>
                      <li><NavLink to="/hair-extensions-for-women" className="nav-link">Tape In & Clip In on Hair Extensions for Women</NavLink></li>
                      <li><NavLink to="/hair-bonding-for-men" className="nav-link">Hair Bonding for Men</NavLink></li>
                      <li><NavLink to="/hair-fixing-for-men" className="nav-link">Hair Fixing for Men</NavLink></li>
                      <li><NavLink to="/human-hair-extension" className="nav-link">Human Hair Extension</NavLink></li>
                      <li><NavLink to="/non-surgical-hair-replacement-for-men" className="nav-link">Non-Surgical Hair Replacement for Men</NavLink></li>
                      <li><NavLink to="/non-surgical-hair-replacement-for-women" className="nav-link">Non-Surgical Hair Replacement for Women</NavLink></li>
                      <li><NavLink to="/volumiser-hair-additions" className="nav-link">Volumiser Hair Additions</NavLink></li>
                    </ul>
                  </li>
                  <li className="has-children">
                    <NavLink to="#Resources" className="nav-link">Resources</NavLink>
                    <ul className="dropdown arrow-top">
                      <li><NavLink to="/blog" className="nav-link">Blog</NavLink></li>
                      <li><NavLink to="/faq" className="nav-link">FAQs</NavLink></li>
                    </ul>
                  </li>
                     <li><NavLink to="/contact-us">Contact Us</NavLink></li>     
                    </ul>
                    </div>
                </div>
                <div className="menu-area">
                    <div className="menu">
                        <ul className="lab-ul">
                        {/* <li><Link to="" className="lab-btn d-none d-md-block"><span>Take a Hair Test</span></Link></li> */}
                       <li><Link to="appointment"  className="lab-btn btn-text d-none d-md-block" spy={true} smooth={true}><span>Get an Appointment</span></Link></li>
                       <li><NavLink to="tel:+91-99585-85649 " className="text-white d-none d-md-block">  <span>Call Us +  </span>91-99585-85649  </NavLink></li>
                        </ul>
                    </div>

                    <div className={`header-bar d-lg-none ${menuToggle ? "active" : "" }`} onClick={() => setMenuToggle(!menuToggle)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div className="ellepsis-bar d-md-none" onClick={() => setSocialToggle(!socialToggle)}>
                        <i className="icofont-info-square"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>   
</header>
    );
}
 
export default Header;