import "./App.css";
import { Routes , Route } from "react-router-dom";
import Home from "./components/Home";
import 'bootstrap/dist/css/bootstrap.min.css';    

import 'swiper/css';
import About from "./components/pages/about-us/About";
import ContactUs from "./components/pages/ContactUs";
import Customizedwigsformen from "./components/pages/services/Customizedwigsformen";
import Customizedwigsforwomen from "./components/pages/services/Customizedwigsforwomen";
import Clipinonhairextensionsformen from "./components/pages/services/Clipinonhairextensionsformen";
import Clipinonhairextensionsforwomen from "./components/pages/services/Clipinonhairextensionsforwomen";
import Nonsurgicalhairreplacementforwomen from "./components/pages/services/Nonsurgicalhairreplacementforwomen";
import Nonsurgicalhairreplacementformen from "./components/pages/services/Nonsurgicalhairreplacementformen";
import Volumiserhairadditions from "./components/pages/services/Volumiserhairadditions";
import Hairbondingformen from "./components/pages/services/Hairbondingformen";
import Hairfixingformen from "./components/pages/services/Hairfixingformen";
import Humanhairextension from "./components/pages/services/Humanhairextension";
import TermsAndConditions from "./components/pages/TermsAndConditions";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";

function App() { 
  return (
    <>
<Routes>
  <Route path="/" element={<Home/>} />
  <Route path="/about-us" element={<About/>} />
  <Route path="/contact-us" element={<ContactUs/>} />
  <Route path="/terms-and-conditions" element={<TermsAndConditions/>} />
  <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
  {/* Services */}
  <Route path="/customized-wigs-for-men" element={<Customizedwigsformen/>} />
  <Route path="/customized-wigs-for-women" element={<Customizedwigsforwomen/>} />
  <Route path="/hair-extensions-for-men" element={<Clipinonhairextensionsformen/>} />
  <Route path="/hair-extensions-for-women" element={<Clipinonhairextensionsforwomen/>} />
  <Route path="/non-surgical-hair-replacement-for-women" element={<Nonsurgicalhairreplacementforwomen/>} />
  <Route path="/non-surgical-hair-replacement-for-men" element={<Nonsurgicalhairreplacementformen/>} />
  <Route path="/volumiser-hair-additions" element={<Volumiserhairadditions/>} />
  <Route path="/hair-bonding-for-men" element={<Hairbondingformen/>} />
  <Route path="/hair-fixing-for-men" element={<Hairfixingformen/>} />
  <Route path="/human-hair-extension" element={<Humanhairextension/>} />
 {/* End Services */}

</Routes>
    </>
  );
}

export default App;
